.video-recorder {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1400px) {
        align-items: center;
    }

    /* phone landscape */
    & .video-recorder-controls {
        @media (max-width: 812px) and (orientation: landscape) {
            position: relative;
        } 
    }

    & .controls {
        max-width: 515px;
        height: 100%;

        /* phone landscape */
        @media (max-width: 812px) and (orientation: landscape) {
            position: absolute;
            height: auto;
            width: 100%;
            bottom: 12px;
            left: auto;
            margin-top: 0;
        }

        /* phone portrait */
        @media (max-width: 812px) and (orientation: portrait) {
            max-width: none;
            width: 100%;
        }
    }

    video, .video-player, .placeholder {
        width: 515px;
        height: 290px;
        border-radius: 10px;
    }

    .placeholder {
        position: relative;
        background-color: black;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        padding: 5px;

        /* tablet */
        @media (orientation: portrait) {
            width: 45vmax;
            height: calc(45vmax * (16 / 9));
        }

        /* phone landscape */
        @media (max-width: 812px) and (orientation: landscape) {
            height: calc(78vmax / (16 / 9));
            width: 78vmax;
        }

        /* phone portrait */
        @media (max-width: 812px) and (orientation: portrait) {
            width: 100%;
            height: calc(43vmax * (16 / 9));
        }
    }

    @media (max-width: 521px) and (orientation: portrait) {
        > div:first-of-type {
            width: 100%;
        }
        video, .video-player {
            max-width: 100%;
            height: 100%;
        }
        .placeholder {
            max-width: 100%;
            height: 78vmax;
        }
        .controls {
            flex-direction: column;
        }
    }
}
