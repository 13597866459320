.knockout-question-bank-item {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    .buttons {
        margin-left: auto;
        display: flex;
        align-items: baseline;

    }
    .preview-card {
        margin-left: 0;
        margin-top: 10px;

        .preview-card-body {
            display: flex;
            flex-direction: column;
            padding-top: 0;
            position: relative;
            .buttons{
                position: absolute;
                right: 0;
                top: -20px;
                margin-left: 10px;
                padding-top: 25px;
            }
        }
    }
}
