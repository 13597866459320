.video-field {
    .video-player {
        max-width: 515px;

        @media (max-width: 1400px) {
            max-width: 78vmax;
        }

        @media (max-width: 812px) {
            margin: 0 auto;
            position: relative;
        }

        @media (min-width: 521px) {
            max-height: 100%;
        }

        &.not-ready-to-play {
            .controls {
                max-width: 515px;
            }
        }

        .controls {
            .control-button {
                width: 48% !important;
                height: 40px;
            }
        }
    }

    .video-field-progress-indicator {
        max-width: 515px;
        height: 290px;
    }
}

