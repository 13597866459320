.chat-wrapper {
    justify-content: flex-end;
    height: 100%;
}

.message-history {
    overflow-y: auto;
    padding-right: 0.8em;

    .chat-date {
        padding: 12px 0;
        margin-bottom: 12px;
        color: #808080;
        font-size: 13px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
    }

    .message {
        clear: both;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;

        .message-metadata {
            color: #808080;
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
            justify-content: flex-start;

            &.user-action {
                width: 100%;
                text-align: center;
            }

            .message-time {
                font-size: 10px;
                line-height: 11px;
                text-transform: uppercase;
            }

            .author-name {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .chat-message {
            border-radius: 15px 0 15px 15px;
            background-color: rgba(176, 185, 207, .2);//#D2D4D7;
            color: #000000;
            padding: 11px;
            font-size: 15px;
            margin-right: 76px;
            margin-left: 30px;
            margin-top: 4px;
            transform-origin: right top;
            word-break: break-word;

            &.typing {
                margin-top: 3px;
            }
        }

        &.own {
            flex-direction: column;
            align-items: flex-start;

            .chat-message {
                border-radius: 15px 15px 15px 0;
                background-color: #3399FF;
                color: #ffffff;
                margin-left: 76px;
                margin-right: 30px;
                margin-top: 0;
                margin-bottom: 4px;
                transform-origin: left bottom;
            }
        }

        .user-join, .user-disconnect {
            color: #808080;
            font-size: 13px;
            line-height: 12px;
        }
    }
}

@media (min-width: 960px) {
    .message-history {
        &.message-history-fullscreen {
            max-height: 70vh;
            .chat-date {
                color: white;
            }
            .message-metadata {
                color: white;
            }
            .user-join, .user-disconnect {
                color: white;
            }
            .message:not(.own) {
                .chat-message {
                    background-color: rgba(176, 185, 207, 1);
                }
            }
        }
    }
    .typing-wrapper {
        &.message-typing-fullscreen {
            .author-name {
                color: white;
            }
        }
    }
}

.typing-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    flex-wrap: wrap;
    align-content: space-between;

    .dots-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 53px;
        height: 22px;

        .dot {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 7px;
            animation-duration: .9s;
            animation-name: typing;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);

            &:nth-child(1) {
                margin-left: 7px;
            }

            &:nth-child(2) {
                animation-delay: .3s;
            }

            &:nth-child(3) {
                animation-delay: .5s;
            }
        }

        @keyframes typing {
            from {
              background-color: #000000;
              margin-top: 0;
            }

            to {
              background-color: #C8C8C8;
              margin-top: -6px;
            }

            50% {
                background-color: #828282;
                margin-top: -3px;
            }
        }
    }

    .author-name {
        font-size: 14px;
        line-height: 16px;
        color: #808080;
        margin-left: 5px;
    }
}

.message-window {
    position: relative;
    height: 59px;
    margin-bottom: 5px;
    max-height: 217px;

    .message-textarea {
        width: 100%;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 3px 5px rgba(176, 185, 207, .3);
        -moz-box-shadow: 0px 3px 5px rgba(176, 185, 207, .5);
        background-color: #ffffff;
        color: #000000;
        padding: 19px 49px;
        font-size: 16px;
        line-height: 18px;
        caret-color: #3399FF;
        height: 100%;
        max-height: 217px;
        resize: none;

        &::placeholder {
            color: #B0B9CF;
        }
    }

    .message-submit, .message-submit:hover, .message-submit:focus {
        box-shadow: none !important;
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important;
        outline: none !important;
        padding: 0 !important;
        margin: 0 !important;
        position: absolute;
        top: 13px;
        right: 10px;
        cursor: pointer;
    }

    .message-submit:disabled, .message-submit.disabled {
        cursor: default;

        svg {
            fill: #B0B9CF;
        }
    }

    &.message-window-fullscreen {
        margin-bottom: 1rem;
    }
}

.chat-history-wrapper {
    max-height: 100vh;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding: 10px;

    .chat-history-title {
        color: #575A68;
        padding: 10px 0;
        border-bottom: 1px solid #eff2f5;
        span {
            margin-left: 12px;
        }
    }
}
