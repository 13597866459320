.question-player-wrapper {
    cursor: pointer;
    position: relative;

    .video-text {
        height: 100%;
        width: 100%;
        background: #000;
        position: absolute;
        text-align: center;
        font-size: 18px;
        z-index: 2;
        color: #fefefe;
        font-weight: 500;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        justify-content: space-evenly;
        @media (max-width: 823px) and (orientation: landscape) {
            flex-direction: row;
            line-height: 23px;
        }
    }

    &.non-clicked {
        cursor: default;
    }

    @media (max-width: 823px) and (orientation: portrait) {
        min-height: 22vmax;
    }

    @media (max-width: 823px) and (orientation: landscape) {
        max-width: 78vmax;
        margin: 0 auto;
        max-height: 31vmax;
    }
}
.question-video {
    pointer-events: none;
}

.controls {
    display: flex;
    margin-top:10px;
    width: 100%;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 823px) and (orientation: landscape), (max-width: 521px) and (orientation: portrait) {
        .control-button, .control-button:hover, .control-button:focus {
            background: rgba(0, 0, 0, 0.35) !important;
            border: 1px solid rgba(255, 255, 255, 0.35) !important;
        }
    }

    .control-button {
        height: 69px;
    }

    &.video-questions {
        .control-button {
            width: 48%;
        }
    }
}

@media (max-width: 521px) {
    .controls {
        align-items: stretch;

        .control-button {
            width: auto !important;
        }

        &.video-questions {
            .control-button {
                width: 100% !important;
            }
        }
    }
}
