
@media (max-width: 876px) {
    .live-interview-share-link {
        .live-interview-share-link-header {
            flex-direction: column !important;
            height: 50px;
            h3 {
                display: none;
            }
        }

        .live-interview-share-link-video-and-chat {
            padding: 10px !important;
            text-align: center;

            .live-interview-share-link-video-element {
                width: 100% !important;
                float: none !important;
            }

            .live-interview-share-link-chat {
                width: 100% !important;
                margin: 0 !important;
                text-align: left;
            }
        }

        .live-interview-share-link-overview {
            padding: 10px !important;
        }

        .live-interview-share-link-video-and-chat-separator {
            width: calc(100% + 20px);
            height: 30px;
            background-color: #f5f7f9;
            margin: 0 0 0 -10px;

            .top {
                height: 10px;
                margin: 0 0 10px 0;
                background-color: #fff;
                border-radius: 0 0 10px 10px;
            }

            .bottom {
                height: 10px;
                background-color: #fff;
                border-radius: 10px 10px 0 0;
            }
        }
    }
}

.live-interview-share-link-container {
    background-color: #f5f7f9;
    position: relative;
    min-height: 100vh;
    width: 100%;
    padding-top: 10px;
}

.live-interview-share-link {
    width: 100%;
    @media (min-width: 986px) {
        width: 986px;
        margin: auto;
    }
    .live-interview-share-link-header {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        h3 {
            margin-bottom: 20px;
            text-align: center;
        }
        img {
            float: right;
            max-height: 75px;
            max-width: 200px;
        }
        @media (max-width: 985px) {
            h3 {
                margin-left: 20px;
            }
        }
    }
    .live-interview-share-link-video-and-chat {
        background-color: #fff;
        border-radius: 5px;
        padding: 20px;
        h4 {
            margin-top: 0;
        }
        .live-interview-share-link-video-element {
            width: calc(100% - 320px);
            border-radius: 5px;
            float: none;
            margin: auto;
            text-align: center;

            &.with-chat {
                float: left;
                margin: 0 10px 0 0;
            }
            &.placeholder {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #030303;
                color: #b6b3b3;
                font-size: 32px;
                text-align: center;
                height: 352px;
                padding: 20px;
            }
        }

        .live-interview-share-link-chat {
            float: right;
            width: 310px;
            height: 352px;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            padding: 10px 0 10px 10px;
            .live-interview-share-link-chat-header {
                border-bottom: 1px solid #eff2f5;
                padding-bottom: 10px;
                font-size: 12px;
                margin: 0 10px 0 0;
                color: #575A68;
                i {
                    margin: 0 5px 0 0;
                    font-size: 18px;
                }
            }
            .live-interview-share-link-chat-messages {
                overflow-y: auto;
                height: 300px;
                padding: 0 10px 0 0;
                .live-interview-share-link-chat-message {
                    display: flex;
                    flex-direction: row;
                    min-height: 40px;
                    margin: 10px 0 0 0;

                    .message-time {
                        color: #2ca2ff;
                        font-size: 12px;
                        padding: 10px 0 0 0;
                        cursor: pointer;
                    }
                    .message-time:hover {
                        color: #81c3f6;
                    }
                    .message-time-disabled {
                        font-size: 12px;
                        padding: 10px 0 0 0;
                        color: #777;
                    }

                    .message {
                        margin: 0 0 0 15px;
                        flex-grow: 2;
                        word-break: break-word;

                        img {
                            height: 35px;
                            width: 35px;
                            margin: 0 10px 0 0;
                            border-radius: 20px;
                            box-shadow: 0 0 5px 0 rgba(34, 60, 80, 0.5);
                            float: left;
                        }

                        .live-interview-share-link-chat-message-text {
                            margin: 7px 0 0 0;
                            padding: 0;
                            font-size: 12px;

                            span.username {
                                color: #848587;
                            }
                        }
                    }
                }
            }
        }
    }
    .live-interview-share-link-overview {
        margin: 10px 0 0 0;
        background-color: #fff;
        border-radius: 5px;
        padding: 10px 20px;
        .live-interview-share-link-overview-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            & > div {
                margin-bottom: 20px;
            }

            .overview-label {
                width: 150px;
                //font-weight: bold;
                font-size: 14px;
                flex-grow: 0;
            }
            .overview-text {
                color: #4a4a4b;
                font-size: 14px;
                width: calc(100% - 155px);
                flex-grow: 1;
            }
        }
    }
}
