.audio-player {
    display: flex;
    flex-direction: row;
    .audio-element-wrapper {
        flex-grow: 1;

        > audio {
            width: 100%;
            height: 100%;
        }
    }

    .playback-rate-wrapper {
        margin-left: 5px;
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
