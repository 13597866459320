.video-question-bank-item {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    .buttons {
        margin-left: auto;
        display: flex;
        align-items: baseline;

    }
    .video-question-bank-item-card {
        margin-left: 0;
        margin-top: 10px;

        .video-question-bank-item-card-body {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            position: relative;
            .buttons{
                float: right;
                margin-right: -20px;
                margin-top: -15px;
            }
        }
    }
}
