.live-interview-page {
    &.live-interview-page-testing, &.live-interview-page-joined {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        height: 100%;
        overflow: hidden;
    }

    &.live-interview-page-testing {
        overflow: auto;
    }

    & .live-interview-page-header {
        & .live-interview-page-header-recording-wrapper {
            margin-right: 1rem;
        }
    }

    & .live-interview-page-content {
        & .audio-video-test-component {
            & .audio-video-test-component-container {
                display: flex;
            }
        }
        & .interview-page-interview-info {
            & .interview-page-interview-info-container {
                &.interview-page-interview-info-container-accepted {
                    background-color: #28a745;
                }
                &.interview-page-interview-info-container-declined {
                    background-color: #dc3545;
                }
            }

            & .interview-page-interview-info-join-interview-buttons {
                padding: 1em;
                display: flex;
                flex-direction: column;
            }

            & .interview-page-interview-info-accept-decline-buttons {
                display: flex;
                justify-content: space-between;
                & button {
                    flex-basis: 45%;
                }
            }

            & .interview-page-interview-info-request-new-time-block {
                padding-top: 1em;
                & button {
                    width: 100%;
                }
            }

            & .interview-page-interview-info-start-join-block {
                padding-top: 1em;
                & button {
                    width: 100%;
                }
            }

            & .interview-page-interview-info-wait-interview-starting {
                margin-top: 1em;
                padding: 1em;
                background-color: red;
                text-align: center;
                color: #FFFFFF;
            }

            & .interview-page-interview-info-interview-state-block {
                padding: 1em;
                background-color: #FFFFFF;
                text-align: center;
                color: #000000;
            }
        }
        & .videos-container {
            & .video-container {
                & .placeholder-font {
                    font-size: xx-large;
                }
                & .video-element-caption {
                    padding: 5px 5px 5px 1em;
                    z-index: 99;
                    & .video-element-user-type {
                        font-size: xx-small;
                        display: block;
                    }
                    & .video-element-user-name {
                        font-size: smaller;
                    }
                }
            }
        }
        & .videos-container.video-grid-4-columns, & .videos-container.video-grid-5-columns {
            & .video-container {
                & .top-right-icon {
                    svg {
                        font-size: 30px!important;
                    }
                }
                & .placeholder-font {
                    font-size: x-large;
                }
            }
        }
        & .videos-container.video-grid-6-columns, & .videos-container.video-grid-7-columns {
            & .video-container {
                & .top-right-icon {
                    top: .6rem;
                    right: .6rem;
                    svg {
                        font-size: 30px!important;
                    }
                }
                & .placeholder-font {
                    font-size: large;
                }
            }
        }
        & .videos-container.video-grid-8-columns,
        & .videos-container.video-grid-9-columns,
        & .videos-container.video-grid-10-columns {
            & .video-container {
                & .top-right-icon {
                    top: .4rem;
                    right: .4rem;
                    svg {
                        font-size: 25px!important;
                    }
                }
                & .placeholder-font {
                    font-size: medium;
                }
            }
        }
        @for $i from 5 through 20 {
            & .videos-container.video-grid-#{$i}-columns {
                & .video-container {
                    & .video-element-caption {
                        padding-top: 2px;
                        padding-bottom: 2px;
                        & .video-element-user-type {
                            @if $i > 6 {
                                display: none;
                            }
                        }
                        & .video-element-user-name {
                            font-size: small;
                        }
                    }
                }
            }
        }
    }

    & .live-interview-video-top-panel {
        background-color: black;
        flex-basis: 55px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
    }

    & .live-interview-video-bottom-panel {
        background-color: white;
        flex-basis: 55px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column-reverse;
    }
}


@media (min-width: 320px) {
    .live-interview-page {
        &.live-interview-page-fullscreen {
            & .videos-container {
                & .video-container {
                    & .main-video-fullscreen-caption {
                        bottom: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (min-width: 960px) {
    //Fullscreen mode
    .live-interview-page {
        &.live-interview-page-fullscreen {
            & .live-interview-page-header {
                padding: 0;
                flex-basis: 55px;
                max-height: 55px;

                & .interview-header-company-name {
                    font-size: x-large;
                }
            }
            & .live-interview-page-content {
                flex-basis: 95%;
                max-height: 95%;
                background-color: black;

                & .interview-page-job-description {
                    * {
                        color: white !important;
                    }
                }

                & .videos-container {
                    & .video-container {
                        & .main-video-fullscreen-caption {
                            top: calc(100% - 85px);
                            text-align: center;
                        }
                    }
                }
            }
            & .interview-page-footer {
                position: absolute;
                width: 100%;
                height: 25px;
                bottom: 0;
                background-color: rgba(0,0,0,.5);
                padding: 0 0 0 3em;
                justify-content: start;
                flex-direction: row;
                color: #FFFFFF;
                left: 0;

                & .interview-page-footer-powered-by {
                    &.interview-page-footer-powered-by-with-logo {
                        display: flex;
                        align-items: center;
                        line-height: 25px;
                        & .interview-page-footer-site-logo {
                            display: none;
                        }
                        & .interview-page-footer-site-name {
                            display: block;
                        }
                    }
                }

                & .interview-page-footer-support {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 959px) {
    .live-interview-page {

        &.live-interview-page-loading, &.live-interview-page-error {
            position: fixed;
            top: 0;
            left: 0;
        }

        &.live-interview-page-error {
            &:before {
                display: block;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }

        &.live-interview-page-testing {
            & .live-interview-page-content {
                & .live-interview-page-content-interview-grid-item {
                    display: none;
                }
            }
        }

        &.live-interview-page-joined {
            position: sticky;
            & .live-interview-page-header, .interview-page-footer {
                display: none;
            }
            & .live-interview-page-content {
                > div {
                    padding: 0;
                }
                & .live-interview-video {
                    flex-direction: column;

                    & .live-interview-reconnecting {
                        position: absolute;
                        z-index: 100;
                        width: 100%;
                        margin: 0;
                        text-align: center;
                    }
                }
                & .live-interview-video-with-active-bottom-panel {
                    & .live-interview-video-bottom-panel {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 200;

                        & .live-interview-chat {
                            padding: 0;
                            height: auto;
                        }

                        & .message-history {
                            overflow-y: scroll;
                            height: 0;
                            flex-grow: 1;
                            padding: 0 1rem;
                        }
                    }
                }
                & .live-interview-video-grid-container {
                    flex-basis: 100%;
                    height: 0;
                    flex-grow: 1;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    & .live-interview-video-top-panel {
        & .video-control-label {
            display: none;
        }
    }
}
