.interview-share-link-dialog {
    min-height: 400px;
    .dialog-content-header {
        margin: 0 0 10px 20px
    }
    .link-container {
        margin: 25px 0 0 0;
        .link-input {
            margin: 0;
            width: 65%;
            @media (max-width: 700px) {
                width: 100%;
            }
            & > div {
                height: 39px;
                background-color: #f0fdf0;
            }
            & > input {
                cursor: text;
                color: #555;
            }
        }
        button {
            float: right;
            @media (max-width: 700px) {
                float: left;
                margin-top: 10px;
            }
        }
    }
    .generate-link-button {
        width: 80%;
        margin: 50px 0 0 10%;
    }
    .views-count {
        margin: 15px 0 5px 0;
        color: gray;
        font-size: 14px;
    }

    .share-link-password-checkbox-container, .hide-participants-names-checkbox-container {
        color: black;
        font-size: 16px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .password-input {
        margin: 0 0 0 14px;
        & > div {
            height: 39px;
        }
        @media (max-width: 500px) {
            width: 90%;
            margin: 15px 0 0 14px;
        }
    }
    .generate-password {
        @media (max-width: 500px) {
            margin-top: 10px;
        }
    }
}

.share-link-password-container {
    text-align: center;
    margin: 15px 0 0 0;
    & > div, button {
        width: 230px;
    }
}
