.videos-container {
    height: 100%;
    display: grid;
    grid-gap: 2px;
    align-items: center;
    grid-auto-rows: minmax(5%, 1fr);
    &.videos-container-joined {
        max-height: 80vh;
    }
    .video-container {
        grid-column: span 2;
        width: 100%;
        height: 100%;
        position: relative;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        video {
            width: 100%;
            height: 100%;
            z-index: 98;
        }

        @for $i from 2 through 20 {
            &.element-shift-#{$i} {
                grid-column: #{$i}/span 2;
            }
        }
    }
    .own-video-container {
        grid-column: span 4;
        width: 100%;
        height: 100%;
        .no-video {
            width: 100vw;
            height: 100vw;
        }
        video {
            width: 100%;
            height: 100%;
        }
    }
    &.video-grid-1-column {
        grid-template-columns: repeat(1, 1fr);
        .video-container {
            grid-column: span 1;
        }
    }
    @for $i from 2 through 20 {
        &.video-grid-#{$i}-columns {
            grid-template-columns: repeat($i*2, 1fr);
        }
    }
}


.fullscreen-video-grid-hide-videos {
    @media (max-width: 959px) {
        position: absolute;
        left: 1em;
        bottom: 1.5em;
        .prev-participant, .next-participant {
            display: none;
        }
    }
    @media (min-width: 960px) {
        .hide-participants {
            position: absolute;
            left: 20px;
            top: 20px;
        }
        .prev-participant {
            position: absolute;
            top: 70px;
            left: 83px;
        }
        .next-participant {
            position: absolute;
            top: 70px;
            right: 100px;
        }
    }
}

.videos-container-hidden {
    @media (max-width: 959px) {
        margin-left: -100%;
    }
    @media (min-width: 960px) {
        margin-top: -100%;
    }
}

.videos-container {
    &.fullscreen-video-grid {
        @media (max-width: 959px) {
            width: 15%;
            min-width: 150px;
            height: 92%;
            align-items: end;
            padding-left: .5em;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: margin-left 0.5s ease;
        }
        @media (min-width: 960px) {
            width: calc(100% - 265px);
            height: 120px;
            margin-left: 124px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            overflow-x: hidden;
            padding: 0 0 0 1px;
            transition: margin-top 0.5s ease;
            &.participants-overflow {
                flex-direction: column;
            }
        }

        .video-container:not(.main-video-element) {
            z-index: 100;
            height: 90%;
            width: 170px;
            cursor: pointer;
            margin-right: 5px;
            @media (max-width: 959px) {
                height: 100%;
                width: 100%;
                max-height: 20%;
                margin-right: 0;
                margin-bottom: 15%;
            }
            &:hover {
                outline: 1px solid rgba(255,255,255,.5);
            }
            > div {
                height: 100%;
                width: 100%;
            }
        }
        .video-container.main-video-element {
            left: 0;
            top: 0;
            position: absolute;
            transition: top 0.3s ease;
            @media (min-width: 960px) {
                &:not(.participants-list-hidden) {
                    top: 120px;
                    height: calc(100% - 120px);
                }
            }

            .video-element-caption {
                padding-left: 5px;
            }
        }
    }
}
