.video-question {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .question-content {
        margin-right: 10px;
    }

    .buttons {
        margin-left: auto;
        display: flex;
        align-items: baseline;
    }

    .editor-card {
        margin-left: 0;
        margin-top: 10px;

        @media (max-width: 521px) and (orientation: portrait) {
            box-shadow: none;
        }

        .editor-card-body {
            padding-top: 0;

            @media (max-width: 521px) and (orientation: portrait) {
                padding: 0;
            }

            .buttons {
                float:right;
                margin-right: -20px;
            }
        }
    }
}
