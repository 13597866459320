.video-question-view {
    .video-question-view-player-indicator {
        height: 16vw;

        @media (max-width: 959px) {
            max-height: 40vh;
            height: 50vw;
        }
    }
}
