.live-interview-preview-page {
    .block-live-interview-recording {
        margin-bottom: 20px;

        .video-chat-container {
            display: flex;
            align-items: stretch;
            column-gap: 20px;

            .video-container {
                flex-basis: 65%;

                .preview-video-container {
                    width: 100%;
                    position: relative;

                    &.no-preview-image {
                        height: 20vw;

                        @media (max-width: 1100px) {
                            height: 30vw;
                        }

                        @media (max-width: 400px) {
                            height: 40vw;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .preview-video-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: .8;
                        background-color: #000000;
                        color: #fff;
                        text-align: center;
                        vertical-align: center;
                        font-size: 30px;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        flex-direction: column;
                        padding: 20px;

                        @media (max-width: 400px) {
                            font-size: 5vw;
                        }
                    }
                }
            }

            &.no-chat {
                display: block;

                .video-container {
                    margin: 0 auto;

                    @media (min-width: 1100px) and (max-width: 1599px) {
                        width: 70%;
                    }

                    @media (min-width: 1600px) {
                        width: 50%;
                    }
                }
            }

            .chat-container {
                flex-basis: 35%;
                position: relative;

                .chat-wrapper {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    overflow: hidden;
                    border: 1px solid #c4c4c4;
                    border-radius: 5px;

                    .chat-history {
                        height: 100%;
                        padding: 13px 0 13px 13px;

                        .chat-header {
                            border-bottom: 1px solid #eff2f5;
                            padding-bottom: 10px;
                            font-size: 12px;
                            margin-right: 10px;
                            color: #575A68;

                            img {
                                margin-right: 10px;
                            }
                        }

                        .chat-messages {
                            height: calc(100% - 40px);
                            padding-right: 10px;
                            overflow-y: auto;

                            .chat-message-wrapper {
                                display: flex;
                                flex-direction: row;
                                min-height: 40px;
                                margin-bottom: 10px;

                                .chat-message-time {
                                    color: #2ca2ff;
                                    font-size: 12px;
                                    padding: 6px 0 0 0;
                                    cursor: pointer;
                                }

                                .chat-message-time:hover {
                                    color: #81c3f6;
                                }

                                .chat-message-time-disabled {
                                    font-size: 12px;
                                    padding: 6px 0 0 0;
                                    color: #777;
                                }

                                .chat-message {
                                    margin: 0 0 0 15px;
                                    flex-grow: 2;
                                    word-break: break-word;

                                    img {
                                        height: 30px;
                                        width: 30px;
                                        margin: 0 10px 0 0;
                                        border-radius: 20px;
                                        float: left;
                                    }

                                    .chat-message-text {
                                        margin: 5px 0 0 0;
                                        padding: 0 0 0 36px;
                                        font-size: 12px;

                                        span.username {
                                            color: #848587;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1100px) {
            .video-chat-container {
                display: block;
                .video-container {
                    width: 100%;
                }
                .chat-container {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 20px;
                    height: 300px;
                }
            }
        }
    }

    .table-overview {
        table-layout: fixed;

        tr {
            td:first-child {
                width: 25%;
                padding-right: 20px;

                @media (max-width: 876px) {
                    width: 30%;
                }

                @media (max-width: 370px) {
                    width: 40%;
                }
            }
            td {
                font-size: 1rem;
                border: none;

                @media (max-width: 530px) {
                    .tags-container {
                        flex-direction: column;

                        > div:first-of-type {
                            width: 100%;
                        }

                        button {
                            margin-top: 10px !important;
                            width: 100%;
                        }
                    }
                }
            }
            .cell-overflow {
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .block-invited-candidates {
        div:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        .block-candidate-card {
            font-size: 1rem;
            padding: 10px 0;
            border-bottom: 1px solid #C8C8C8;

            .block-candidate-name {
                background: url("../img/invitation-card-user.png") no-repeat;
                padding: 1px 0 10px 40px;
            }
            .block-candidate-email {
                background: url("../img/invitation-card-email.png") no-repeat 4px 6px;
                padding: 1px 0 10px 40px;
            }
            .block-candidate-phone {
                background: url("../img/invitation-card-phone.png") no-repeat 5px 4px;
                padding: 1px 0 10px 40px;
            }
            .block-candidate-invitation-link {
                background: url("../img/invitation-card-link.png") no-repeat 3px 0;
                padding: 1px 0 0 40px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
