.recorder-controls {
    .record-indicator {
        width: 35px;
        height: 35px;
        font-size: 0;
        background-color: red;
        border: 0;
        border-radius: 35px;
        margin-left: -5px;
        margin-right: 10px;
        outline: none;

        &.not-recording {
            background-color: darkred;
        }

        &.recording {
            animation-name: pulse;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            @keyframes pulse {
                0% {
                    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, .3);
                }
                65% {
                    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, .3);
                }
                90% {
                    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
                }
            }
        }

        &.recording-pending {
            border-radius: 50%;
            border: 5px solid;
            border-color: #ff852d transparent #ff852d transparent;
            animation: lds-dual-ring 1.2s linear infinite;
            @keyframes lds-dual-ring {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
