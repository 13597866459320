.video-progress-indicator {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 18px;
    background-color: black;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .indicator-text {
        margin-bottom: .5em;
    }
}
