.rdt {
  input.form-control {
    font-size: 16px !important;
  }
}

.filter-form {
  border: 2px solid #eee;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  background-color: #fafafa;
  margin: 0 0 20px 0;
  .filter-form-actions {
    margin-top: 20px;
  }
}
.filter-form.hidden {
    display: none;
}

.form-group {
  div > p, .form-input-error {
      font-size: 16px;
  }

  .required:before {
    content: "*";
    right: 0;
    top: 0;
    font-weight: bold;
    color: red;
    position: absolute;
  }
}

.required:before {
  content: "\002A";
  right: 0;
  top: -5px;
  font-weight: bold;
  font-size: 16px;
  color: red;
  position: absolute;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-info:focus {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-light:focus {
  background-color: transparent;
  border-color: transparent;
}

.jobs-filter-open-modal {
    margin: 38px 65px 0 -80px;
    float: left;
    color: #bbb;
    z-index: 2;
    cursor: pointer;
    &:hover {
        color: #777;
    }
}
.jobs-filter-modal-visible-overflow {
    overflow-y: visible !important;
}
