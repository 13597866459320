@charset "UTF-8";
//Important! In production vendor styles are always loaded before local ones

//vendor styles
@import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import '../../../node_modules/font-awesome/css/font-awesome.css';
@import '../../../node_modules/video-react/styles/scss/video-react.scss';

//local styles
@import './material-dashboard-pro-react.scss';
@import './custom.scss';
@import './video-react-customization.scss';
@import './interview-share-link-dialog.scss';
@import './live-interview-share-link.scss';
@import './live-interview-preview-page.scss';
@import './audio-player.scss';
