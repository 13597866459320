.company-subscription-tabs {
    display: flex;
    width: 200px;
    margin: 20px 10px 10px 0;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    .company-subscription-tabs-details {
        margin: 0 10px 0 0;
    }
    p {
        margin: 0;
        font-weight: 400;
    }

    a {
        color: #0087FF;
        font-weight: 700;
    }
}

.subscription-turn-off-autopay-modal {
    .subscription-modal-button-green, .subscription-modal-button-red {
        width: calc(50% - 5px);
        float: left;
    }
    .subscription-modal-button-green {
        margin: 0 10px 0 0;
    }
}

.partner-subscription-tabs {
    font-size: 20px;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
    font-variant: small-caps;
    color: #c4c4c4;

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: -10px 0 20px 0;
    a, .active {
        color: #566789;
        text-decoration: none;
    }

    a:hover {
        opacity: 0.7;
    }

    i.fa {
        font-size: 12px;
        margin: 10px 15px 0 15px;
    }
}

.partner-company-subscription {
    font-family: "Source Sans Pro", sans-serif;
    .actions {
        outline: none;
        border: 0;
        float: right;
        width: 130px;
        height: 50px;
        background: rgba(45, 125, 246, 0.11);
        border-radius: 5px;
        color: #2D7DF6;
        margin: -50px 0 0 0;
        font-size: 20px;
        font-weight: 700;
        font-family: Roboto, sans-serif;
        &:hover {
            opacity: 0.8;
        }
    }

    .actions-dropdown {
        width: 276px;
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        right: 30px;
        top: 150px;
        font-family: "Source Sans Pro", sans-serif;
        z-index: 5;

        .actions-dropdown-header, .actions-dropdown-separator {
            background: rgba(86, 103, 137, 0.66);
            height: 30px;
            font-size: 15px;
            font-weight: 600;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 0 0 20px;
        }
        .actions-dropdown-header {
            border-radius: 5px 5px 0 0;
        }
        ul {
            list-style: none;
            font-size: 20px;
            color: #3F4253;
            margin: 0;
            padding: 0;
            li {
                height: 50px;
                border-top: 1px solid #F4F4F4;
                vertical-align: middle;
                padding: 10px 0 0 20px;
                cursor: pointer;
                &:hover {
                    opacity: 0.75;
                    background-color: #f3f3f3;
                }
                a {
                    color: #3F4253;
                    text-decoration: none;
                    height: 100%;
                    width: 100%;
                    display: block;
                }
                .disabled {
                    color: #c3c3c7;
                    cursor: default;
                }
            }
        }
    }

    .plan-info {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 9px 0 rgba(34, 60, 80, 0.15);
        padding: 30px 30px 15px 50px;
        display: flex;
        justify-content: space-between;
        min-height: 150px;

        .no-plan-selected {
            font-size: 32px;
            color: rgba(86, 103, 137, 0.33);
            font-weight: bold;
            padding: 7px 0 0 0;
        }

        .info {
            .plan-label {
                font-weight: bold;
                font-size: 15px;
                color: #566789;
                font-family: "Public Sans", sans-serif;
                text-transform: uppercase;
            }

            .plan-name {
                float: left;
                font-size: 36px;
                line-height: 50px;
                font-weight: 700;
                margin: -5px 0 0 0;
            }

            .subscription-status {
                float: left;
                height: 40px;
                padding: 5px 15px;
                border-radius: 5px;
                font-size: 20px;
                font-weight: 700;
                margin: 0 20px;

                &.active {
                    background-color: rgba(103, 203, 107, 0.22);
                    color: #67CB6B;
                }

                &.inactive {
                    background-color: rgba(253, 104, 104, 0.22);;
                    color: rgb(253, 104, 104);;
                }
            }

            .plan-price {
                float: left;
                height: 40px;
                padding: 5px 15px;
                border-radius: 5px;
                font-size: 20px;
                font-weight: 700;
                background-color: #E3F2FF;
                color: #0087FF;
            }

            .next-payment-date {
                color: #566789;
                font-size: 18px;
                line-height: 23px;
                margin: 15px 0 10px 0;
            }

            .subscription-limitations {
                color: #566789;
                float: left;
                .plan-card-limitation {
                    margin: 0 0 10px 0;
                    .fa-check {
                        background-color: #E3F2FF;
                        color: #0087FF;
                        height: 32px;
                        width: 30px;
                        text-align: center;
                        padding: 8px 0 0 0;
                        margin: 0 10px 0 0;
                        border-radius: 15px;
                    }

                    span {
                        font-size: 18px;
                    }
                }
            }
            .subscription-limitations-usages {
                color: #566789;
                float: left;
                margin: 0 0 0 70px;

                p:not(.limitation-usage-label) {
                    margin: 0 0 20px 0;
                }

                .limitation-usage-label {
                    margin: 0 0 18px 0;
                    font-size: 18px;
                    line-height: 24px;
                }

                .limitation-usage-label:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .subscription-buttons {
            width: 400px;

            button {
                margin-bottom: 20px;
            }
        }
    }

    .subscription-account-information {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 9px 0 rgba(34, 60, 80, 0.15);
        padding: 30px 30px 15px 50px;
        margin: 20px 0 0 0;

        .account-information-label {
            font-size: 14px;
            font-weight: bold;
            color: #566789;
            font-family: "Public Sans", sans-serif;
        }

        table {
            width: 100%;
            tr {
                border-bottom: 1px solid #EFF2F5;
                td {
                    padding: 20px 0;
                }
            }
            tr:last-child {
                border-bottom: 0;
            }
            .name {
                width: 280px;
                color: #A3A6B6;
                font-size: 18px;
            }
            .value {
                color: #566789;
                font-size: 18px;
            }

            .custom-switcher {
                margin: 5px 0 0 0;
            }
        }
    }

    .subscription-transactions {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 9px 0 rgba(34, 60, 80, 0.15);
        padding: 5px 30px 30px 50px;
        margin: 20px 0 0 0;
        table {
            width: 100%;
            th {
                color: rgba(86, 103, 137, 0.33);
                font-weight: bold;
                font-size: 22px;
                padding: 25px 0;
            }

            tbody tr {
                border-bottom: 1px solid #EFF2F5;
            }

            td {
                padding: 25px 0;
                color: #3F4253;
                font-size: 20px;

                &.date {
                    color: rgba(86, 103, 137, 0.33);
                }

                .view-receipt {
                    text-decoration: none;
                    background: none;
                    border: 0;
                    outline: 0;
                    color: #149CFF;
                    &:hover {
                        opacity: 0.73;
                    }
                }
            }
        }
    }
}


