.custom-switcher {
    position: relative;
    background-color: #67CB6B;
    cursor: pointer;
    display: flex;
    transition: padding-left, background-color;
    transition-duration: 300ms;
    border-radius: 35px;

    width: 74px;
    height: 34px;
    padding-top: 4px;
    padding-left: 44px;

    &.off {
        padding-left: 4px !important;
        background-color: #FD6868;
        .custom-switcher-text-on {
            visibility: hidden;
        }
        .custom-switcher-text-off {
            visibility: visible;
        }
    }

    .custom-switcher-circle {
        background-color: #fff;
        box-shadow: 0 1.12px 1.12px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        height: 26px;
        width: 26px;
    }
    .custom-switcher-text-on, .custom-switcher-text-off  {
        color: #fff;
        font-weight: 700;
        font-family: "Source Sans Pro", sans-serif;
        position: absolute;
        transition-delay: 3s;
        transition: visibility;
        top: 6px;
        font-size: 15px;
    }
    .custom-switcher-text-on {
        left: 14px;
    }

    .custom-switcher-text-off {
        visibility: hidden;
        left: 33px;
    }

    &.disabled {
        cursor: default;
        background-color: #a6a3a3;
        .custom-switcher-circle {
            background-color: #d7d5d5;
        }
        .custom-switcher-text-on, .custom-switcher-text-off {
            color: #d7d5d5;
        }
    }

    &.small {
        width: 41px;
        height: 21px;
        padding-top: 3px;
        padding-left: 22px;

        .custom-switcher-circle {
            height: 15px;
            width: 15px;
        }

        .custom-switcher-text-on, .custom-switcher-text-off {
            font-size: 8px;
            top: 4px;
        }

        .custom-switcher-text-on {
            left: 7px;
        }

        .custom-switcher-text-off {
            left: 22px;
        }
    }
}
