.progress-wrapper {
    width: 141px;
    height: 18px;
    border-width: 1px;
    border-style: solid;
    position: relative;

    .progress-bar {
        height: 100%;
    }

    .progress-percents {
        position: absolute;
        top: 0;
        right: 2px;
        color: black;
        line-height: 16px;
        &.red {
            color: red;
        }
        &.full, &.full.red {
            color: white;
        }
    }
}

.invite-candidates-modal {
    overflow-y: visible !important;
    #invite-candidates-modal-title {
        .fa-close {
            cursor: pointer;
        }

        .fa-close:hover {
            opacity: 0.8;
        }
    }

    #invite-candidates-modal-body {
        height: 155px;
        padding: 30px 30px;
        overflow-y: visible;
    }
}

.send-invitations-container {
    .form-group {
        margin: 5px 0;
    }

    .add-invitation {
        cursor: pointer;
        text-decoration: underline;
        margin: 15px 0 30px 0;
        display: inline-block;
    }

    .on-demand-invitations-suggestions-list {
        position: absolute;
        z-index: 1;
    }
}

.on-demand-invitation-icons {
    vertical-align: middle !important;
    width: 110px;

    img:first-of-type {
        margin-right: 5px;
    }

    img {
        cursor: pointer;
        color: red;
        &:hover {
            opacity: 0.7;
        }
    }

    .invitation-remove {
        margin: 0 0 0 15px;
    }
}

.on-demand-interview-expires-at-calendar-container {
    width: 210px;
    vertical-align: middle !important;

    .expires-at-invitation {
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
        img {
            margin: -2px 12px 0 0;
        }
    }

    //remove bootstrap styles from react-datetime widget
    tbody, thead, tfoot {
        font-size: 1rem;
        tr, td, th {
            background-color: transparent !important;
            border:none;
        }
        td:hover {
            background-color: #efefef !important;
        }
        .dow {
            border-bottom: 1px solid #e3e3e3;
        }
    }
}

.on-demand-invitation-expires-at-calendar {
    .rdtPicker {
        width: 398px;
        left: -128px;
        &:after, &:before {
            left: 50%;
        }

        .scheduled-time {
            justify-content: start;
            padding: 20px 0 0 0;
            .schedule-time {
                label {
                    text-align: left;
                }
                input {
                    font-size: 16px;
                }
            }
        }

        .controls-wrapper {
            text-align: center;
            margin: 10px 0;
            .done {
                background-color: #9c27b0;
                color: white;
                margin: 0 10px 0 30px;
            }
            .reset {
                border: solid 1px #9c27b0;
                color: #9c27b0;
            }
        }
    }
}
.on-demand-invitation-expires-at-calendar.hide-time {
    .rdtPicker {
        width: 260px;
        left: 0;

        .done {
            margin: 0 10px 0 0;
        }
    }
}

.on-demand-invitation-custom-email-modal {
    font-family: "Inter", sans-serif;
    .ql-toolbar {
        border: none !important;
    }
    .ql-container {
        border-top: 1px solid #ccc !important;
        border-radius: 10px;
        .ql-editor {
            color: #556A83;
            font-size: 15px;
        }
    }

    .reset-to-default-message {
        display: block;
        margin: 0 0 10px 0;
        color: #2D7DF6;
        font-size: 15px;
        &:hover {
            opacity: 0.75;
            text-decoration: none;
        }
    }

    .subscription-modal-button-green, .subscription-modal-button-gray-outlined {
        width: calc(50% - 5px);
        float: left;
    }
    .subscription-modal-button-green {
        margin: 0 10px 0 0;
    }
}

/* @TODO remove this when IO-1530 will be merged */
.subscription-modal-button-gray-outlined {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 1px solid rgba(86, 103, 137, 0.33);
    color: #566789;
    background: none;
    &:hover {
        opacity: 0.87;
    }
}
