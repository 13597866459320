.video-test-recorder {
    .video-test-recorder-stopping-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        background: black;
    }

    .video-test-recorder-timer {
        text-align: center;
        position: absolute;
        justify-content: center;
        top: 0;
        z-index: 1000;
        width: 100%;
        background: rgba(1,1,1,0.5);
        color: white;
        padding: 0.3rem;
        font-size: 1.2em;
        display: flex;
        align-items: center;
    }

    .video-test-recorder-starting-overlay {
        position: absolute;
        padding: 1vw;
        background: rgba(1,1,1,0.5);
        text-align: center;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        z-index: 1000;

        .video-test-recorder-starting-content {
            margin: 0 auto;
            & .loader {
                margin: 0 auto 1rem;
                background-color: transparent;
            }
            & > span {
                font-size: 1.5rem;
                color: white;
            }
        }
    }

    .video-test-recorder-player {
        z-index: 1000;
        position: absolute;
        padding-top: 0 !important;
        height: 100% !important;
        border-radius: 0;
        & > video {
            position: relative !important;
        }
    }
}
