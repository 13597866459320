.knockout-question {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .question-content {
        margin-right: 10px;
    }

    .buttons {
        margin-left: auto;
        display: flex;
        align-items: baseline;
    }

    .editor-card {
        margin-left: 0;
        margin-top: 10px;

        .editor-card-body {
            padding-top: 0;
            .buttons {
                position: absolute;
                right: 0;
                top: 5px;
            }
        }
    }
}

.droppable {
    position: relative;
    margin-left: 10px;

    $droppableIconColor: #ccc !default;

    &:before {
        content: '.';
        position: absolute;
        left: -17px;
        top: -13px;
        font-size: 20px;
        line-height: 26px;
        color: darken($droppableIconColor, 20%);
        text-shadow: (
            0 5px darken($droppableIconColor, 20%),
            0 10px darken($droppableIconColor, 20%),
            5px 0 darken($droppableIconColor, 20%),
            5px 5px darken($droppableIconColor, 20%),
            5px 10px darken($droppableIconColor, 20%)
            );
    }
}
