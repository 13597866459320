.request-video-questions {
    display: grid;
    @media (min-width: 1400px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        .column {
            border-radius: 10px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
            padding: 15px;

            h2 {
                margin-left: 5px;
            }
        }
        .knockout-questions {
            order: 1;

        }
        .question-bank-search {
            order: 2;
        }
    }
    @media (max-width: 568px) {
        display: block;
    }
}

.ghost-placeholder {
    position: absolute;
    border: 1px dashed #ccc;
}

.tips {
    color: #00acc1;
    margin-bottom: 15px;
    margin-left: 5px;
}
