.company-dashboard {
    .top-boxes {
        display: flex;
        justify-content: center;
        @media (max-width: 1475px) {
            display: block;
        }
        .top-box {
            height: 220px;
            border: 2px solid purple;
            margin: 15px 15px 20px 0;
            float: left;
            width: 15%;
            @media (max-width: 1475px) {
                width: calc(20% - 15px);
            }
            @media (max-width: 1200px) {
                width: calc(50% - 15px);
            }
            @media (max-width: 500px) {
                width: calc(100%);
                margin-right: 0;
            }
            .top-box-title {
                text-align: center;
                font-size: 14px;
                font-weight: bold;
                margin: 3px 0 10px 0;
            }
            ul {
                list-style: none;
                margin: 0 20px 0 10px;
                padding: 0;
                font-size: 14px;
                li {
                    width: 100%;
                    display: flex;
                    span {
                        display: block;
                        flex-grow: 0;
                    }
                    span.space {
                        flex-grow: 1;
                        border-bottom: 1px dotted;
                        margin: 0 3px 6px 3px;
                    }
                }
            }
        }
    }

    .dashboard-charts {
        .date-range {
            padding: 0 0 20px 20px;
            #dashboard-period {
                width: 300px;
                float: left;
                @media (max-width: 410px) {
                    width: 200px;
                }
            }
            .dashboard-period-custom {
                width: 375px;
                float: left;
                margin: 0 0 0 20px;
                .period-custom-from, .period-custom-to {
                    width: 85px;
                    float: left;
                    margin: 0 20px 0 0;
                }
                @media (max-width: 1045px) {
                    float: none;
                    width: 250px;
                    margin: 50px 0 20px 40px;
                    .period-custom-from {
                        margin: 0 20px 15px 3px;
                    }
                }
            }
            .fa-spinner {
                margin: 10px 0 0 10px;
                color: #7a7a7a;
            }
        }
        .date-range.fixed {
            padding-top: 20px;
            position: fixed;
            top: 0;
            background-color: white;
            width: 100%;
            z-index: 2;
            -webkit-box-shadow: 1px 10px 8px -10px rgba(0,0,0,0.67);
            -moz-box-shadow: 1px 10px 8px -10px rgba(0,0,0,0.67);
            box-shadow: 1px 10px 8px -10px rgba(0,0,0,0.67);
        }

        .chart-container.title-bellow {
            h4 {
                margin-top: 50px;
            }
            .chart-top-x-axis {
                margin-top: -80px;
            }
            .chart-top-buttons {
                margin-top: -89px;
            }
        }

        .chart-container {
            padding: 15px 0 30px 0;
            margin: 0 0 10px 10px;
            border: 1px solid #eaeaea;
            float: left;

            h4 {
                margin-top: 20px;
            }

            .chart-top-x-axis {
                float: left;
                margin: -50px 0 0 25px;
                background-color: #9b4be7;
                border-radius: 3px;
                padding: 7px;
                font-size: 12px;
                color: #fefefe;
                span:hover {
                    cursor: pointer;
                    color: #efefef;
                }
                span.active {
                    color: #fafafa;
                    font-weight: bold;
                    text-decoration: underline;
                }
            }

            .chart-top-buttons {
                float: right;
                margin: -59px 15px 0 0;

                .expand-chart {
                    padding-left: 7px;
                    padding-right: 7px;
                    margin-left: 5px;

                    i {
                        font-size: 16px;
                    }
                }
                .expand-chart:focus {
                    outline: none;
                }

                @media (max-width: 550px) {
                    float: none;
                    text-align: center;
                    margin: 0 0 0 0;
                }
            }

            .recharts-custom-legends {
                width: 80%;
                text-align: center;
                margin: 0 0 0 calc(10% + 10px);

                span {
                    display: block;
                    float: left;
                    width: calc(50% - 5px);
                    padding: 3px;
                    background-color: #efefef;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                    }

                    @media (max-width: 580px) {
                        float: none;
                        width: 100%;
                        margin: 5px 0 0 0;
                    }
                }

                span:first-child {
                    margin: 0 10px 0 0;
                }

                span.disabled {
                    background-color: #f8f8fa;
                }
            }
            .recharts-custom-legends.full-width {
                margin: 0 0 0 35%;
                @media (max-width: 580px) {
                    margin: 0 0 0 15%;
                }
            }

            .yAxis .recharts-cartesian-axis-tick:last-child {
                transform: translate(0, -6px);
            }
        }

        .chart-container.pie {
            .recharts-default-legend {
                display: inline-block;
                float: right;
                text-align: left !important;
                li {
                    display: block !important;
                }
            }
        }
    }
}
