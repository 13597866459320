.transactions-list {
    td {
        vertical-align: middle;
    }
    button.transaction-view {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #149CFF;
        border: none;
        outline: none;
        background: none;
        &:hover {
            opacity: 0.73;
        }
    }
}


.transaction-status {
    height: 40px;
    width: 114px;
    text-align: center;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;

    &.success {
        background-color: rgba(103, 203, 107, 0.22);
        color: #67CB6B;
    }

    &.failed {
        background-color: rgba(253, 104, 104, 0.22);;
        color: rgb(253, 104, 104);
    }

    &.pending {
        background-color: rgba(253, 223, 104, 0.22);;
        color: rgb(253, 211, 104);;
    }

    &.canceled {
        background-color: rgba(197, 195, 192, 0.22);;
        color: rgb(110, 109, 107);
    }
}

.transaction-modal-container {
    margin: auto;
    width: calc(100% - 40px);
    .field-row {
        border-bottom: 1px solid #D3D8E1;
        display: flex;
        flex-direction: row;
        padding: 20px 0;

        font-family: "Source Sans Pro", sans-serif;
        font-size: 20px;
        color: #566789;

        .field-name {
            width: 55%;
            font-weight: 700;
        }

        .field-value {
            text-align: right;
            width: 45%;
            font-weight: 400;
        }

        .transaction-status {
            float: right;
        }
    }

    .field-row.price {
        padding: 10px 0;
        border-bottom: none;
    }
    .field-row.amount {
        padding: 10px 0;
        color: #0087FF;
        .field-value {
            font-weight: 700;
        }
    }

    .btn-success {
        width: 100%;
        height: 42px;
    }
}
