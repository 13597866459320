.reviews-list-item {
    min-width: 465px;
    margin: 0 0 20px 0 !important;
    @media (max-width: 520px) {
        width: 100%;
        min-width: auto;
    }

    .review-card {
        height: 100%;
    }
}
