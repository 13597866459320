.video-react {
    border-radius: 10px;

    .video-react-video {
        border-radius: 9px;
        height: 99.9%;
    }

    > .video-react-control-bar {
        border-radius: 0 0 9px 9px;
    }
}

.live-interview-preview-page {
    .video-react-big-play-button {
        left: calc(50% - 1.5em);
        top: calc(50% - 1.5em);
        width: 3em;
        height: 3em;
        border-radius: 3em;
        opacity: .7;
        background-color: #c4c4c4;
        border: none;
        &:before {
            position: static;
        }
        @media (max-width: 300px) {
            width: 2em;
            height: 2em;
            left: calc(50% - 1em);
            top: calc(50% - 1em);
        }
    }
}
