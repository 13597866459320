.partner-dashboard-plan-select {
    h2 {
        font-size: 28px;
        color: #3F4253;
        text-align: center;
        margin: 0 0 30px 0;
    }

    .plan-select-period-tabs {
        text-align: center;
        margin: 0 0 20px 0;
        button {
            outline: none;
            border: 0;
            width: 260px;
            height: 40px;
            font-size: 14px;
            background: none;
            color: #C4C4C4;
            padding: 0 0 10px 0;
            &.active {
                color: #0087FF;
                border-bottom: 1px solid #0087FF;
                font-weight: bold;
            }
        }
    }

    .plan-select-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 50px;

        .plan-card-status {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 315px;
            height: 30px;
            margin: 0 0 10px 0;
            background: rgba(123, 97, 255, 0.22);
            border-radius: 5px;
            font-size: 14px;
            font-weight: bold;
            color: #7B61FF;
            &.private {
                background: rgba(255, 128, 159, 0.22);
                color: #FF809F;
            }
        }

        .plan-select-card {
            margin: 0 25px 25px 0;
        }
    }

    .plan-select-fixed-bottom {
        width: calc(100% - 275px);
        background-color: #fff;
        bottom: 0;
        right: 0;
        position: fixed;
        padding-bottom: 50px;
        z-index: 5;

        .plan-select-selected-plan-text {
            text-align: center;
            color: #0087FF;
        }

        .plan-select-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0 0 0;

            .cancel, .continue {
                height: 72px;
                width: 300px;
                border-radius: 10px;
                font-size: 18px;
                color: #0087FF;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                margin: 0 50px 0 0;
                outline: none;

                &:hover {
                    opacity: 0.75;
                }
            }

            .cancel {
                border: 1px solid #0087FF;
                box-sizing: border-box;
            }

            .continue {
                background-color: #0087FF;
                color: #fff;
                border: 0;

                &.disabled {
                    background-color: #C4C4C4;
                    cursor: default;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.plan-select-overuse-plan-modal {
    font-family: "Source Sans Pro", sans-serif;
    color: #566789;
    p {
        font-size: 20px;
        font-weight: 400;
    }

    .limitations-column {
        width: 50%;
        float: left;
        .column-label {
            font-weight: 700;
            font-family: Roboto, sans-serif;
            font-size: 15px;
            margin: 15px 0;
        }
    }

    .limitations-column-row {
        font-size: 20px;
        line-height: 25px;
        margin: 0 0 20px 0;
        &.overused {
            color: #FD6868;
        }
    }

    .btn-success {
        width: 100%;
    }
}
