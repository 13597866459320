.ql-container {
    font-size: 16px;
    font-family: inherit;
}

.ql-container .ql-editor {
    min-height: 100px;
    max-height: 500px;
}

.btn-primary:focus, .btn-primary.focus {
    background-color: #007bff !important;
    border-color: #007bff !important;
}
