.partner-company-payment-method-select-container {
    width: calc(100% - 410px);
    float: left;

    h2 {
        font-size: 28px;
        text-align: center;
        margin: 0 0 20px 0;
    }

    .partner-company-payment-methods-list {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 9px 0 rgba(34, 60, 80, 0.15);
        padding: 30px 140px;

        .payment-method-card {
            float: left;
            margin: 0 20px 20px 0;
        }

        .enable-autopay {
            color: #566789;
            font-size: 20px;
            margin: 5px 0 0 0;
            width: 245px;

            .custom-switcher {
                float: left;
                margin: 0 0 0 10px;
            }

            .fa {
                float: left;
                margin: 7px 0 0 10px;
            }
        }
    }

    .no-charge {
        width: 72%;
        height: 80px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #566789;
        cursor: pointer;
        box-shadow: 0 0 9px 0 rgba(34, 60, 80, 0.15);
        margin: 20px 0 0 14%;
        border-radius: 10px;
        &:hover {
            opacity: 0.75;
        }
        &.selected {
            border: 2px solid #67CB6B;
            box-sizing: border-box;
            .fa-check-circle {
                font-size: 25px;
                color: #67CB6B;
                position: absolute;
                top: -11px;
                right: -14px;
                background-color: #fff;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin: 20px 0 0 0;
        button, a {
            width: 300px;
        }

        .disabled {
            background-color: #C4C4C4;
            cursor: default;
            &:hover {
                opacity: 1;
            }
        }
    }
}

.partner-company-payment-method-select-upgrade-summary {
    position: absolute;
    right: 14px;
    top: 0;
    height: calc(100% - 55px);
    width: 400px;
    padding: 100px 20px;
    background-color: #fff;
    box-shadow: -5px 0px 6px -4px rgba(34, 60, 80, 0.12);

    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    color: #566789;
    font-size: 20px;

    h4 {
        font-size: 30px;
        font-weight: 600;
    }

    hr {
        width: 360px;
    }

    .plan-name, .price-details {
        text-align: left;
        span {
            float: right;
        }
    }

    .price-details {
        margin-top: 20px;
        &.total {
            font-weight: bold;
            color: #0087FF;
        }
        &.next-payment-date {
            opacity: 0.67;
            margin-top: 10px;
        }
    }

    .plan-card-limitations {
        .plan-card-limitation {
            margin: 18px 0 0 0;
            text-align: left;
            .fa-check {
                height: 25px;
                width: 25px;
                background: rgba(0, 135, 255, 0.1);
                padding: 3px 0 0 1px;
                border-radius: 15px;
                text-align: center;
            }
            span {
                font-size: 20px;
                margin: 0 0 0 10px;
            }
        }
    }
}

.payment-failed-modal {
    font-family: "Source Sans Pro", sans-serif;
    text-align: center;

    .transaction-icon {
        margin: 0 auto 15px;
    }

    p {
        color: #566789;
        font-size: 20px;
        margin-bottom: 25px;
    }

    .btn-success {
        width: 100%;
    }
}

.payment-method-delete-modal {
    font-family: "Source Sans Pro", sans-serif;

    p {
        color: #566789;
        font-size: 20px;
        margin-bottom: 25px;
    }

    .subscription-modal-button-green, .subscription-modal-button-red {
        width: calc(50% - 5px);
        float: left;
    }
    .subscription-modal-button-green {
        margin: 0 10px 0 0;
    }
}
