//Common styles for OnDemand and Live Interview pages
.interview-page {
    position: absolute;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    & .interview-page-header {
        padding: 10px;
        flex-basis: 80px;
        height: 80px;
        overflow: hidden;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: #f4f4f4;
        z-index: 101;

        & .interview-header-grid {
            height: 100%;
            & .interview-header-company-container {
                display: flex;
                align-items: center;
                height: 100%;
            }

            & .interview-header-buttons-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                & .btn {
                    margin-right: 1rem;
                }
                & .btn:nth-last-child(1) {
                    margin-right: 2rem;
                }
            }
        }

        & .interview-header-company-logo {
            flex-basis: 50%;
            flex-grow: 1;
            max-width: 100%;
            object-fit: contain;
            display: contents;
            & > img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        & .interview-header-company-name {
            font-size: 26px;
            align-self: center;
            color: rgba(0, 0, 0, 0.87);
            padding: 0 10px;
            flex-basis: 50%;
            flex-grow: 1;
            flex-shrink: 1;
        }
    }

    & .interview-page-content {
        flex-basis: calc(100% - 80px - 80px);
        max-height: calc(100% - 80px - 80px);
        background-color: white;
        flex-grow: 1;
        flex-shrink: 0;

        & .interview-page-content-grid-container {
            height: 100%;
        }

        & .interview-page-job-description { //Fix for copy-pasted styles in job description
            * {
                color: #212529 !important;
            }
        }

        & .interview-page-job-info {
            padding: 2rem;

            & .interview-page-job-header {
                margin-bottom: 1rem;

                & .interview-page-job-title {
                    font-weight: normal;
                }
            }

            & .interview-page-job-info-list {
                list-style: none;
                padding-left: 1em;
                border-left: 4px solid red;
                & li:not(:last-child) {
                    margin-bottom: 1em;
                }
                & b {
                    text-transform: uppercase;
                }
            }
        }

        & .interview-page-interview-info {
            padding: 2rem;

            & .interview-page-interview-info-title {
                color: #FFFFFF;
                background-color: #000000;
                font-size: larger;
                font-weight: bold;
                text-align: center;
                width: 100%;
                padding: 0.5em;
            }

            & .interview-page-interview-info-container {
                color: #FFFFFF;
                background-color: #000000;
                padding: 1em 1em 0 1em;
            }


            & .interview-page-interview-info-block {
                padding-bottom: 1em;

                & .interview-page-interview-info-block-title {
                    font-size: smaller;
                }

                & .interview-page-interview-info-block-content {
                    display: block;
                    font-size: large;
                    font-weight: bold;
                    overflow-wrap: break-word;
                }
            }

            & .interview-page-interview-info-actions {
                background-color: #000000;
                padding-bottom: .5em;
            }
        }
    }

    &.live-interview-page-testing {
        .interview-page-content {
            max-height: none;

            .interview-page-content-grid-container {
                width: 100%;
                margin: 0;
            }

            .interview-page-interview-info {
                padding: 1.8rem;
            }
        }
    }

    & .interview-page-footer {
        height: 80px;
        width: 100%;
        overflow: hidden;
        background-color: white;
        flex-grow: 0;
        flex-shrink: 0;
        flex-direction: column;
        padding: 3px;
        display: flex;
        flex-basis: 80px;
        justify-content: space-between;
        align-items: center;
        font-size: larger;
        text-align: center;

        & .interview-page-footer-support {
            width: 100%;
            height: 20px;
            font-weight: 500;
        }

        & .interview-page-footer-powered-by {
            height: 100%;
            & .interview-page-footer-site-logo {
                height: 100%;
                margin-left: 1em;
                max-height: 44px;
            }

            & .interview-page-footer-site-name {
                margin-left: .3em;
            }

            &.interview-page-footer-powered-by-with-logo {
                & .interview-page-footer-site-name {
                    display: none;
                }
            }
        }
    }
}

.interview-page-decline-invitation-dialog {
    & .interview-page-decline-invitation-dialog-actions {
        justify-content: center;

        & .interview-page-decline-invitation-dialog-actions-button {
            padding: 8px 30px;
            margin: 0 10px;
        }
    }
}

@media (max-width: 959px) {
    .interview-page {
        position: relative;
        overflow: visible;
        height: auto;
        min-height: 100%;

        & .interview-page-header {
            height: 55px;
            flex-basis: 55px;
            padding: 0;

            & .interview-header-company-name {
                font-size: 1.2rem;
                font-weight: bold;
            }

            & .interview-header-company-name:not(.interview-header-company-name-without-logo) {
                display: none;
            }

            & .interview-header-buttons-container {
                & .btn {
                    font-size: .8rem;
                    margin-right: .3rem;
                }
                & .btn:nth-last-child(1) {
                    margin-right: .3rem;
                }
            }
        }

        & .interview-page-content {
            max-height: none;
            flex-grow: 1;

            & .interview-page-content-grid-container {
                position: relative;
                flex-flow: column-reverse;
            }

            & .interview-page-interview-info {
                padding: 0;
            }
        }

        & .interview-page-footer {
            height: auto;
            flex-basis: auto;

            & .interview-page-footer-support {
                height: auto;
                padding: 0 1rem;
                font-size: 15px;
                margin-bottom: .5rem;
            }

            & .interview-page-footer-powered-by {
                font-size: smaller;
                height: 25px;
            }
        }
    }
}

@media (min-width: 960px) and (max-width: 991px) {
    .interview-page {
        position: fixed;
    }
}
