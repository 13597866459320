.partner-company-order-show {
    h2 {
        font-size: 28px;
        text-align: center;
        margin: 0 0 20px 0;
    }

    .order-show {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 9px 0 rgba(34, 60, 80, 0.15);
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        table {
            text-align: left;
            font-size: 18px;
            color: #566789;
            width: 530px;
            margin: 20px 0 0 0;
            tr {
                td:first-child {
                    padding: 15px 30px 15px 0;
                    font-weight: bold;
                }
                td:nth-child(2) {
                    text-align: right;
                }
                td.plan-name {
                    padding-left: 30px;
                    div {
                        display: list-item;
                    }
                }
            }
            tr.bordered {
                border-bottom: 1px solid #D3D8E1;
            }
            tr.blue-color {
                color: #0087FF;
            }
            img {
                margin: -2px 3px 0 0;
            }
        }
    }

    .partner-company-order-show-buttons {
        display: flex;
        justify-content: space-between;
        width: 630px;
        margin: 20px auto;
        a {
            width: 300px;
        }
    }
}
