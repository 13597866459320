//Add bootstrap styles to theme
body {
    background-color: #fff;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    inline-size: auto;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

button {
    outline: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1, .h1 {
    font-size: 2.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}
//End bootstrap styles

.on-demand-text-answer-preview {
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    background-color: #fafafa;
    white-space: pre-line;
}

.on-demand-audio-answer-preview {
    width: 100%;
}

ul.on-demand-video-question-preview-statistic {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 13px;
    li {
        clear: both;
        .statistic-name {
            display: block;
            float: left;
            width: 208px;
        }
        .statistic-value {
            display: block;
            float: left;
            width: 100px;
            @media (max-width: 370px) {
                margin: 0 0 10px 0;
            }
        }
    }
}

.radio-group-class {
    float: left;
    margin: -15px 0 0 20px;
    display: block !important;
}


.switch-icon-checked {
    color: $blue !important;
}
.switch-icon-bar {
    background-color: $blue !important;
}

.create-job-modal-button {
    width: 70%;
}

@media (max-width: 599px) {
    .add-new-question, .add-screening-question {
        text-align: center;
    }
}

.create-interview-success-modal-content {
    .share-link-text {
        margin: 5px 0 10px 15%;
        text-align: left;
        font-weight: bold;
        i.fa {
            padding: 10px;
            border-radius: 3px;
            background-color: #0B7FC7;
            color: #fefefe;
        }
    }
    .share-link-field {
        margin: 5px 0 10px 15%;
        text-align: left;
        width: 70%;
        .link-input {
            width: calc(100% - 95px);
            @media (max-width: 475px) {
                width: 100%;
            }
            & > div {
                height: 39px;
                background-color: #f0fdf0;
                color: #777;
            }
        }
        button {
            color: #333;
            margin: -1px 0 0 7px;
            padding: 0;
            @media (max-width: 475px) {
                margin: 5px 0 0 0;
            }
            i.fa {
                color: #777;
                background-color: #efefef;
                padding: 11px;
                border-radius: 3px;
            }
            &:hover {
                text-decoration: none;
                opacity: 0.7;
            }

        }
    }
}

.create-job-modal-close-button, .create-interview-modal-close-button {
    cursor: pointer;
    color: #777;
    @media (max-width: 645px) {
        margin: -10px -10px 0 0;
    }
}
.create-job-modal-close-button:hover {
    color: #333;
}

.grid-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(11%, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
    grid-auto-rows: minmax(max-content, 1fr);

    @media (max-width: 960px) {
        grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
    }

    @media (max-width: 521px) {
        grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    }

    > .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.live-interview-grid-buttons {
    grid-template-columns: repeat(auto-fill, minmax(11.2%, 1fr));
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-weight: 600;

    @media (max-width: 1092px) {
        grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
    }

    @media (max-width: 521px) {
        grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    }
}

.no-border {
    td {
        border: 0;
    }
}

.placeholders-list-card {
    width: 500px;
    ul {
        list-style: none;
        li {
            margin: 3px 0;
            .placeholder {
                padding: 4px;
                border-radius: 3px;
                border: 1px dotted #aaa;
                display: inline-block;;
            }
        }
    }
}

.tags-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    > div:first-of-type {
        flex: 1;
    }

    .tags-message {
        width: 100%;
    }
}

.video-question-settings {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    width: 100%;
}

.iframe-on-demand-interview {
    max-width: 1350px;
    margin: auto;
    .iframe-on-demand-interview-header {
        height: 100px;
        border-bottom: 3px solid #000;
        margin: 0 0 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 876px) {
            flex-direction: column-reverse;
        }

        .iframe-on-demand-interview-candidate {
            margin: 20px;
            width: 250px;
            float: left;

            .iframe-on-demand-interview-label {
               margin: 0 0 5px 0;
            }
            .iframe-on-demand-interview-full-name {
                font-size: 24px;
                font-weight: bold;
                display: inline-block;
                margin-left: 45px;
            }
        }

        .share-link-preview-interview-title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        img {
            max-height: 75px;
            max-width: 200px;
        }
    }
}

.time-limits {
    input {
        max-width: 38px;
    }
}

.live-interviews-all-tabs:focus {
    outline: none !important;
}

.flash-messages-container {
    .alert {
        .text-message {
            float: left;
            width: 92%;
        }
        i.fa-close {
            float: right;
            cursor: pointer;
            margin: 4px 0 0 0;
        }
        i.fa-close:hover {
            opacity: 0.7;
        }
    }
}

.required-asterisk {
    color: red;
}

.layout-gray {
    background-color: #F5F7F9;

    .block-rounded {
        min-width: 250px;
        background-color: $white;
        border-radius: 10px;
        padding: 20px;

        h3 {
            color: #3F4253;
            margin: 0 0 10px 0;
        }
    }
}
