.manage-on-demand-interview-expiration-settings {
    margin: 0 0 10px 0;
    .expiration-settings-title {
        margin: 10px 0 0 0;
    }
    .expiration-settings-expires-at {
        width: 90px;
        margin: 7px 0 0 5px;
        float: left;
        .form-group {
            margin-bottom: 0;
        }
    }
    .expiration-settings-invitation-expiration-days {
        width: 50px;
        margin: 7px 0 0 5px;
        float: left;
        .form-group {
            margin-bottom: 0;
            input {
                text-align: center;
            }
        }
    }
    .expiration-settings-invitation-info {
        margin: 0 0 0 15px;
        font-size: 14px;
        color: #444;
    }
}
