@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600&family=Roboto:wght@400;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

.admin-plan-list {
    table {
        tr th {
            font-weight: bold;
            font-size: 15px;
            text-transform: uppercase;
            color: #566789;
            font-family: "Public Sans", sans-serif !important;
        }

        tr td {
            vertical-align: middle;
            font-weight: 400;
            font-size: 16px;
            color: #566789;
            font-family: "Public Sans", sans-serif !important;

            .plan-limitation-hidden {
                color: #bbb4b4;
            }
        }
    }
}

.admin-plan-form-header {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #3F4253;
    margin: 7px 0 15px -2px;
}

.admin-plan-form {
    display: flex;
    .fields {
        float: left;
        width: calc(100% - 420px);
        .half-size {
            width: calc(50% - 10px);
            float: left;
            &.mr-20 {
                margin: 0 20px 0 0;
            }
        }
        .billing-period-checkboxes {
            width: 150px;
            float: left;
            p {
                margin: 0;
            }
        }
        .billing-period-fields {
            p {
                margin: 0 0 7px 0;
            }
            width: 200px;
            float: left;
            margin: 0 0 0 15px;
            .form-group {
                margin: 0 0 20px 0;
            }
        }

        .plan-type {
            .radio-group-class {
                margin: -15px 0 0 0;
            }
        }

        .section-label {
            color: #566789;
            font-size: 15px;
            font-family: "Public Sans", serif;
            font-weight: 600;
        }

        .plan-limitations {
            .plan-limitation {
                height: 70px;
                width: 100%;
                background-color: rgba(86, 103, 137, 0.11);
                border: 1px solid rgba(86, 103, 137, 0.11);
                border-radius: 5px;
                box-sizing: border-box;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 10px 10px 20px;

                .title {
                    text-transform: uppercase;
                    color: #506A85;
                    font-size: 15px;
                    width: 200px;
                    font-family: "Public Sans", serif;
                    font-weight: 600;
                }

                .quantity {
                    width: 40%;
                    margin: 0 20px 0 0;
                    @media (max-width: 1500px) {
                        width: 33%;
                    }
                }

                .unlimited, .hide-this-feature {
                    padding: 24px 0 0 0;
                }

                .hide-this-feature {
                   margin: 0 0 0 10px;
                   width: 218px
                }
            }
        }

        .plan-change-warning {
            border: 1px solid #D97F00;
            border-radius: 5px;
            box-sizing: border-box;
            background: rgba(217, 127, 0, 0.05);
            display: flex;
            justify-content: start;
            color: #D97F00;
            align-items: center;
            font-weight: 400;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 20px;
            min-height: 42px;
            padding: 10px 10px;
            i {
                margin-right: 10px;
            }
        }
    }
    .plan-preview {
        width: 400px;
        margin: 0 0 100px 20px;
        float: left;
        border-left: 1px solid #C4C4C4;
        text-align: center;
        padding: 5px 0 30px 0;

        .plan-preview-label {
            color: #566789;
            font-weight: 600;
            text-align: left;
            margin: 0 10px 25px 20px;
        }

        .plan-preview-period {
            margin: 0 0 20px 0;
            button {
                background: rgba(255, 255, 255, 0);
                border: none;
                font-size: 14px;
                font-weight: bold;
                color: #C4C4C4;
            }
            button:not(.active):hover {
               opacity: 0.7;
            }
            button.active {
                color: #0087FF;
            }
            button:focus {
                outline: none;
            }
        }
    }
    .bottom-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin: 30px 0 0 0;
        a {
            margin: 0 30px 0 0;
        }
        a, button {
            float: left;
            width: 300px;
        }
    }
}
