.entity-video-player {
    .controls {
        .control-button {
            width: 48% !important;
        }

        @media (max-width: 812px) and (orientation: landscape) {
            margin-top: 0;
            position: absolute;
            height: auto;
            bottom: 8px;
            left: auto;
        }
    }
}
