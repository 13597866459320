@mixin answer-button {
    outline: none !important;
    margin-left: 10px;
    margin-right: 10px;
}
@mixin video-button-success {
    @include answer-button;
    background-color: rgba(97, 223, 63, .5);
    &:hover {
        background-color: rgba(76, 175, 50, .5);
    }
}
@mixin video-button-danger {
    @include answer-button;
    background-color: rgba(255, 54, 47, .5);
    &:hover {
        background-color: rgba(200, 42, 36, .5);
    }
}
@mixin video-button-default {
    @include answer-button;
    background-color: rgba(150, 150, 150, .7);
    &:hover {
        background-color: rgba(118, 118, 118, .7);
    }
}
@mixin audio-button-default {
    @include answer-button;
    background-color: rgb(150, 150, 150);
    &:hover {
        background-color: rgb(118, 118, 118);
    }
}
@mixin audio-button-danger {
    @include answer-button;
    background-color: rgb(255, 54, 47);
    &:hover {
        background-color: rgb(200, 42, 36);
    }
}

@mixin video-alert-message {
    position: absolute;
    color: white;
    z-index: 20;
    top: 1em;
    left: calc(50% - 25%);
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.on-demand-interview-page {
    & .on-demand-interview-page-help-button {
        outline: none !important;
        & .on-demand-interview-page-help-button-text {
            font-size: medium;
            margin-left: 3px;
            @media (max-width: 450px) {
                display: none;
            }
        }
    }

    & .on-demand-interview-page-loading {
        margin-bottom: 0;
    }

    & .on-demand-interview-steps {
        & .on-demand-interview-steps-completed-step {
            color: #28a745;
        }
        &.on-demand-interview-steps-canceled {
            & .on-demand-interview-steps-active-step {
                color: red;
            }
        }
    }
    & .on-demand-interview-page-content {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        & .time-elapsed-display {
            position: absolute;
            top: 0;
            text-align: center;
            cursor: default;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            width: 100%;
            z-index: 10;
            color: white;
            font-size: 1.2em;
            padding: .3rem;
            background: rgba(1,1,1,0.5);
            border-radius: 10px 10px 0 0;
        }

        & .interview-page-interview-info {
            & .interview-page-interview-info-container {
                & .interview-page-interview-info-block {
                    &.interview-page-interview-expired {
                        color: red;
                    }
                }
            }
            & .interview-page-interview-info-actions {
                &.on-demand-interview-page-interview-info-actions {
                    padding: 1em;
                    display: flex;
                    flex-direction: column;

                    & .on-demand-interview-page-decline-button {
                        margin-top: 1em;
                    }
                }
            }
        }

        & .on-demand-interview-page-about-content {
            margin: 0 10%;

            & .on-demand-interview-page-about-tips {
                & .on-demand-interview-page-tips-highlighted-phrase {
                    text-decoration: underline;
                    font-weight: bold;
                }
            }

            & .on-demand-interview-page-about-form-container {
                & .on-demand-interview-page-about-form-title {
                    text-align: center;
                }
                & .on-demand-interview-page-about-form-control {
                    margin-bottom: 2rem;
                }
                & .on-demand-interview-page-about-form-control-industry {
                    margin-top: 1rem;
                }
                & .on-demand-interview-page-about-form-control-password {
                    margin-top: 2rem;
                }
                & .on-demand-interview-page-upload-resume-label {
                    font-weight: normal;
                    font-size: large;
                    color: rgba(0, 0, 0, 0.87);
                }

                & .on-demand-interview-page-about-form-actions {
                    display: flex;
                    align-items: center;
                }

                & .on-demand-interview-page-about-form-checkbox-error {
                    margin-top: -15px;
                    & > p {
                        font-size: 16px;
                    }
                }

                & .on-demand-interview-page-existing-candidate-login-link {
                    font-size: medium;
                    text-decoration: underline;
                    text-decoration-style: dotted;
                    cursor: pointer;
                    opacity: 0.7;
                    width: fit-content;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        & .on-demand-interview-page-test-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            flex-grow: 1;

            & .on-demand-interview-page-audio-video-test-component-container {
                flex-grow: 1;
                display: flex;
                width: 100%;
            }

            & .on-demand-interview-page-test-component-grid {
                flex-grow: 1;
                height: 100%;
            }

            & .on-demand-interview-page-test-video-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;

                & .audio-video-test-component {
                    flex-grow: 1;
                    height: auto;
                    & .audio-video-test-component-container {
                        display: flex;
                        & video {
                            max-height: 54vh;
                        }
                        & .video-react-fullscreen {
                            & video {
                                max-height: initial;
                            }
                        }
                    }
                }

                & .on-demand-interview-page-test-video-element {
                    width: 40vw;
                    height: 25vw;
                    background: black;
                    color: white;
                    font-size: x-large;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                & .on-demand-interview-page-test-video-button {
                    margin-top: 1rem;
                }
            }

            & .on-demand-interview-page-test-device-select-container {
                margin-bottom: 2rem;
                margin-left: 2rem;
            }

            & .on-demand-interview-page-test-actions {
                width: 100%;
                text-align: center;
                & .on-demand-interview-page-start-button {
                    max-width: 300px;
                    width: 100%;
                }
            }
        }

        & .on-demand-interview-page-question-content {
            max-width: 1170px;
            margin: 0 auto;
            width: 100%;

            & .on-demand-interview-intro-outro-player {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 570px;

                & > iframe {
                    height: 315px;
                    width: 560px;
                }

                & .question-player-wrapper {
                    width: 100%;
                }

                & .on-demand-interview-intro-outro-player-skip-button {
                    margin-top: 1.5rem;
                }
            }

            & .on-demand-interview-page-video-questions-buttons-container {
                display: flex;
                justify-content: center;
            }

            & .on-demand-interview-page-video-questions-grid-container {
                width: 100%;
                margin: 0;
            }

            & .question-box, & .answer-box {
                margin: 0 0 30px 0;
            }

            & .on-demand-interview-page-video-question-card {
                margin: 0;
                height: 100%;
                & .video-card-header {
                    position: relative;
                    min-height: 7em;

                    & .select-another-answer-type-header-button {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }

                & .video-recorder {
                    & .placeholder {
                        width: 100%;
                    }
                }
            }

            & .question-box {
                & .video-question-card-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            & .answer-box {
                & .video-answer-card-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    & .video-answer-text-container {
                        max-height: 379px;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        & .video-answer-textarea {
                            width: 100%;
                            padding: 10px;
                            border-radius: 10px;
                            flex-grow: 1;
                        }

                        & .text-answer-buttons-container {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            margin-top: 10px;
                            height: 79px;
                            & .select-another-answer-type-button {
                                height: 69px;
                            }
                        }
                    }

                    & .answer-error {
                        color: red;
                        font-weight: bold;
                        @media (min-width: 960px) {
                            font-size: 13px;
                            margin-top: 16px;
                        }
                    }
                }

                & .answer-type-block {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    max-height: 379px;

                    & .answer-type-select-box {
                        width: 100%;
                        border-radius: 10px;
                        vertical-align: middle;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;
                        background-color: black;
                        flex-grow: 1;
                        max-height: 290px;

                        & .answer-type-hint-text {
                            color: white;
                            font-size: large;
                            flex-grow: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        & .answer-type-select-options {
                            width: 100%;
                            padding: 0 10%;
                            flex-grow: 3;
                            display: flex;
                            flex-direction: column;
                            justify-content: stretch;
                            & .answer-type-select-option {
                                display: flex;
                                flex-grow: 1;
                                margin-bottom: 15px;
                                align-items: center;
                                justify-content: center;
                                font-size: 2em;
                                width: 100%;
                                color: #222;
                                cursor: pointer;
                                border-radius: 10px;
                                background-color: #45cec1;

                                & > i {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    & .answer-type-controls-area {
                        height: 79px;
                        margin-top: 10px;
                    }
                }

                & .answer-recorder-desktop-info-block {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    top: 5em;
                    width: 100%;
                    height: 3em;
                    left: 0;
                    padding: 0 20px;

                    &.answer-recorder-desktop-info-block-with-time-limitation {
                        justify-content: flex-start;
                    }

                    &.answer-recorder-desktop-info-block-with-attempt-limitation {
                        justify-content: space-between;
                    }

                    &.answer-recorder-desktop-info-block-with-time-limitation.nswer-recorder-desktop-info-block-with-attempt-limitation {
                        justify-content: space-between;
                    }

                    & .answer-recorder-recording-time, .answer-recorder-recording-attempts {
                        display: flex;
                        align-items: center;
                    }

                    & .answer-recorder-recording-time {
                        & .answer-desktop-time-elapsed-display {
                            font-size: 2em;
                            cursor: default;
                        }
                    }
                }

                & .video-answer-recorder, .audio-answer-recorder {
                    & .video-recorder-controls, & .audio-recorder-controls {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-top: 10px;
                        height: 79px;

                        & .controls {
                            margin-top: 0;
                            max-width: initial;
                            & .control-button {
                                flex-basis: 48%;
                            }
                        }
                    }
                }

                & .video-answer-recorder {
                    position: relative;
                    display: flex;
                    flex-grow: 1;
                    max-height: 379px;

                    & .re-records-limit-label {
                        position: absolute;
                        top: 5em;
                        right: 2em;
                        display: block;
                    }

                    & .answer-recorder {
                        display: flex;
                        flex-grow: 1;
                        & .video-recorder {
                            width: 100%;
                            justify-content: flex-end;

                            @media (max-width: 823px) and (orientation: landscape) {
                                max-width: 78vmax;
                                margin: 0 auto
                            }

                            & .video-recorder-video {
                                display: flex;
                                flex-grow: 1;
                                width: 100%;

                                & > .placeholder {
                                    z-index: 50;
                                    height: initial;
                                }

                                & .video-react {
                                    z-index: 50;
                                }
                            }

                            & .answer-recording-own-stream {
                                pointer-events: none;
                                max-height: inherit;
                                max-width: inherit;
                                width: 100%;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: black;
                                border-radius: 10px;

                                & .placeholder {
                                    width: 100%;
                                    height: 100%;
                                }

                                & video {
                                    width: 100%;
                                    height: auto;
                                    max-height: 290px; //Fix for Safari

                                    @media (max-width: 1400px) {
                                        height: 100%;
                                        margin-bottom: -5px;
                                      }

                                    @media (max-width: 823px) and (orientation: landscape) {
                                        margin-bottom: 0;
                                    }
                                }

                                /* Tablets in portrait, because it have vertical video in this mode (in landscape mode it will have horizontal video and can use default desktop styles) */
                                @media (orientation: portrait) {
                                    text-align: center;

                                    video {
                                        max-width: 100%;
                                        height: 100%;
                                        width: 45vmax;
                                    }

                                    .placeholder {
                                        width: 45vmax;
                                        height: calc(45vmax * (16 / 9)); // aspect ratio 16:9 for all videos
                                    }
                                }

                                /* Phones in portrait and landscape modes */
                                @media (max-width: 812px) {
                                    video {
                                        max-width: 100%;
                                        height: 100%;
                                        width: 78vmax;
                                    }
                                }

                                /* phone landscape */
                                @media (max-width: 812px) and (orientation: landscape) {
                                    .placeholder {
                                        height: calc(78vmax / (16 / 9));
                                        width: 78vmax;
                                    }
                                }

                                /* phone portrait */
                                @media (max-width: 812px) and (orientation: portrait) {
                                    .placeholder {
                                        width: 100%;
                                        height: calc(43vmax * (16 / 9));
                                    }
                                }

                                .controls {
                                    height: auto;
                                    max-width: 100%;

                                    & .control-button {
                                      width: 48%;
                                    }
                                }
                            }
                        }
                    }
                }

                & .audio-answer-recorder {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    position: relative;

                    & .change-device-buttons-container {
                        & .change-microphone-button {
                            color: black;
                        }
                    }

                    & .audio-recorder {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;

                        & .audio-recorder-container {
                            flex-grow: 1;
                            background-color: white;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            & audio {
                                width: 80%;
                                padding: 10px;
                                //align-self: flex-end;
                                border-radius: 0;
                            }

                            & .audio-recorder-overlay {
                                color: white;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                position: absolute;
                                top: 0;
                                left: 0;

                                & .loader {
                                    background-color: white;
                                    & > div {
                                        background-color: white;
                                        color: rgba(0,0,0,.7);
                                    }
                                }
                            }

                            & .audio-text-overlay {
                                color: black;
                                opacity: .7;
                                & > h4 {
                                    padding-bottom: 100px;
                                }
                            }

                            & .audio-recorder-visualizer {
                                width: 100%;
                                align-self: center;
                                height: 200px;
                                border-radius: 10px;
                            }
                        }

                        & .audio-recorder-controls {
                            & .start-recording-button {
                                position: absolute;
                                top: 35%;
                                width: 300px;
                                & .record-indicator {
                                    display: none;
                                }
                            }
                        }
                    }

                    & .audio-answer-think-time {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        top: calc(35% + 85px);
                        @media (max-width: 959px) {
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    & .change-device-buttons-container {
        position: absolute;
        width: 100%;
        padding: .2em;
        display: flex;
        z-index: 20;
        justify-content: space-between;

        &.change-device-buttons-container-with-video:not(.change-device-buttons-container-with-audio) {
            justify-content: flex-start;
        }
        &.change-device-buttons-container-with-audio:not(.change-device-buttons-container-with-video) {
            justify-content: flex-end;
        }

        &.change-device-buttons-container-hidden {
            display: none;
        }

        & .change-microphone-button, .change-camera-button {
            color: white;
            outline: none !important;
            opacity: .7;
            & svg {
                font-size: 2rem;
            }
        }
    }



    &.on-demand-interview-page-whole-screen {
        position: fixed;
        top: 0;
        left: 0;

        & .on-demand-interview-page-test-content {
            & .on-demand-interview-page-mobile-audio-video-test-component-container {
                position: relative;
            }

            &.on-demand-interview-page-test-content-testing-started {

                & .on-demand-interview-page-mobile-audio-video-test-component-container {
                    & .video-test-recorder-starting-overlay {
                        border-radius: 10px;
                    }

                    & .on-demand-interview-page-mobile-audio-video-settings-button
                    {
                        display: none;
                    }

                    & .audio-video-test-component {
                        & .audio-video-test-controls {
                            bottom: .5rem;
                        }
                    }
                }
            }

            & .on-demand-interview-page-mobile-audio-video-test-component-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 100%;
                height: 100%;

                & .audio-video-test-component {
                    margin: 0;
                    padding: 0;
                    flex-basis: 100%;
                    flex-grow: 1;
                    width: 100%;

                    & .audio-video-test-component-container {
                        position: absolute;
                        left: 0;
                        height: 100%;
                        display: flex;

                        & .test-video-element-no-video-placeholder {
                            border-radius: 10px;
                        }

                        & .video-test-recorder {
                            width: inherit;
                            height: inherit;
                            position: absolute;

                            & > .placeholder {
                                border-radius: 10px;
                            }

                            & > .video-react {
                                width: inherit;
                                height: inherit !important;
                                display: flex;
                                border-radius: 10px;
                            }
                        }

                        & video {
                            width: inherit;
                            height: auto;
                            border-radius: 10px;
                        }

                        & .video-react-control-bar {
                            display: none;
                        }
                    }

                    & .audio-video-test-controls {
                        z-index: 1000;
                        position: absolute;
                        bottom: .5rem;;
                        left: 0;
                        width: 100%;

                        & .buttons-container {
                            display: flex;
                            flex-direction: column;

                            & .start-testing-button {
                                @include video-button-danger;
                            }
                            & .starting-recording-button {
                                @include video-button-default;
                            }
                            & .test-again-button {
                                @include video-button-success;
                            }
                            & .stop-recording-button {
                                @include video-button-danger;

                                svg {
                                    display: none;
                                }
                            }
                            & .end-testing-button {
                                @include video-button-danger;
                            }
                        }

                        & .audio-video-test-troubleshoot {
                            display: none;
                        }
                    }
                }
            }

            & .on-demand-interview-page-test-actions {
                bottom: .5rem;
                display: flex;
                flex-direction: column;

                & .on-demand-interview-page-start-button {
                    max-width: none;
                    width: initial;
                }
            }
        }
    }
}

.on-demand-interview-page-login-dialog {

    & .on-demand-interview-page-login-dialog-text {
        text-align: center;
        font-weight: 400;
        font-size: large;
    }

    & .on-demand-interview-page-login-form-control {
        margin-bottom: 1rem;
    }

    & .on-demand-interview-page-forgot-password {
        text-align: center;
    }

    & .on-demand-interview-page-login-dialog-actions {
        text-align: center;
        margin-top: 1rem;

        & .on-demand-interview-page-dialog-login-button {
            width: 80%;
        }
    }
}

.on-demand-interview-page-help-dialog {
    & .on-demand-interview-page-help-dialog-title {
        font-weight: bold;
    }

    & .on-demand-interview-page-help-dialog-content {
        text-align: center;
    }

    & .on-demand-interview-page-help-dialog-actions {
        justify-content: center;

        > button {
            padding: 10px 25px;
        }
    }
}

@media (max-width: 959px) {
    .on-demand-interview-page {
        & .on-demand-interview-page-header {
            & .interview-header-grid {
                & .interview-header-company-container {
                    padding-right: 0 !important;
                }
                & .interview-header-buttons-container {
                    padding-left: 0 !important;
                    & .btn:nth-last-child(1) {
                        margin-right: 10px;
                    }
                }
            }
        }

        & .on-demand-interview-steps {
            & .on-demand-interview-stepper-component {
                padding: 5px 5px 0;

                & .on-demand-interview-steps-label {
                    margin-top: 5px;
                }
            }
        }

        & .interview-page-content {
            padding: 10px 10px 0 10px;

            & .on-demand-interview-video-notification {
                @include video-alert-message;
            }

            & .on-demand-interview-page-about-content {
                margin: 0 5%;
                & .on-demand-interview-page-about-tips {
                    margin: 10px 0 0;
                }
            }
            & .on-demand-interview-page-question-content {
                margin: 0;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                position: relative;

                & .knockout-questions-grid-container {
                    overflow: scroll;
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 0;
                }

                & .on-demand-interview-page-question-controls {
                    display: flex;
                    padding: 0 !important;
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: 0;

                    > button {
                        flex-grow: 1;
                    }
                }

                & .on-demand-interview-page-video-question-text-intro {
                    > button {
                        width: 100%;
                    }
                }

                & .on-demand-interview-intro-outro-player {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    max-width: initial;
                    align-items: stretch;
                    margin: 0;

                    & .question-player-wrapper {
                        height: 100%;
                        max-height: 100%;
                        overflow: hidden;

                        & .video-react {
                            height: 100%;
                            padding-top: 0 !important;

                            & > video {
                                position: relative;
                            }
                        }
                    }

                    & > iframe {
                        width: 100%;
                        height: calc(100vw/1.77);
                        max-height: calc(100% - 41px);
                    }

                    & .controls.video-questions {
                        display: none;
                    }

                    & .on-demand-interview-intro-outro-player-skip-button {
                        margin-top: .5rem;
                    }
                }

                & .on-demand-interview-page-video-questions-buttons-container {
                    & > button {
                        flex-grow: 1;
                    }
                }

                & .on-demand-interview-page-video-questions-grid-container, .on-demand-interview-page-video-questions-buttons-container {
                    margin: 0;
                    width: 100%;
                }

                & .on-demand-interview-page-video-questions-grid-container {
                    flex-grow: 1;
                    flex-direction: column;

                    & .question-box, .answer-box {
                        margin: 0;
                        flex-grow: 1;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: 0 !important;
                        min-height: 29vh;

                        & .on-demand-interview-page-video-question-card {
                            box-shadow: none;
                            flex-grow: 1;

                            & .card-body {
                                padding: 0;
                                display: flex;
                                flex-direction: column;

                                & .video-card-header {
                                    height: initial;
                                    min-height: initial;

                                    & .select-another-answer-type-header-button {
                                        display: none;
                                    }
                                }

                                & .video-answer-text-container {
                                    margin-top: 15px;
                                }
                            }
                        }
                    }

                    & .question-box {
                        max-height: 38vh;

                        &.question-box-video-hidden {
                            flex-grow: 0;
                            margin-bottom: 0;
                            min-height: initial;
                        }

                        & .video-card-header {
                            position: relative;

                            & .video-question-toggle-video-button {
                                position: absolute;
                                top: -6px;
                                right: 0;
                                outline: none !important;
                            }
                        }

                        & .question-text-block {
                            text-align: justify;

                            & .question-full-title {
                                max-height: 20vh;
                                overflow-y: auto;
                            }

                            & .hide-title {
                                display: none;
                            }
                        }

                        & .question-video-block {
                            display: flex;
                            flex-grow: 1;

                            &.question-video-block-hidden {
                                display: none;
                            }

                            & .question-player-wrapper {
                                display: flex;
                                flex-grow: 1;
                                flex-direction: column;

                                & .question-video {
                                    padding-top: 0 !important;
                                    flex-grow: 1;
                                }
                            }
                            & .controls.video-questions {
                                display: none;
                            }
                        }

                        & .question-video-think-time {
                            @media (max-width: 960px) {
                                flex-direction: column;
                                .controls.video-questions {
                                    display: flex;
                                    margin: 0 auto;
                                    button {
                                        height: 40px !important;
                                        width: 100px !important;
                                    }
                                }
                                .video-text {
                                    .controls.video-questions {
                                        button {
                                            background-color: rgba(255, 255, 255, 0.85) !important;
                                            border-color: rgba(0, 0, 0, 0.35) !important;
                                            color: #000000;
                                        }
                                    }
                                }
                            }

                            @media (min-width: 729px) and (max-width: 829px) {
                                .question-player-wrapper {
                                    width: 72vmax;
                                }
                            }

                            @media (max-width: 823px) and (orientation: landscape) {
                                .question-player-wrapper {
                                    min-height: 12vmax;
                                }
                            }
                        }
                    }

                    & .answer-box {

                        & .answer-type-block {
                            max-height: initial;
                            & .answer-type-select-box {
                                height: auto;
                                flex-grow: 1;
                                padding: 10px;
                                justify-content: center;
                                max-height: initial;

                                & .answer-type-select-options {
                                    width: 100%;
                                    padding: 0;

                                    & .answer-type-select-option {
                                        width: 100%;
                                        border-radius: 3px;
                                        text-align: center;
                                        justify-content: center;

                                        & > i {
                                            display: none;
                                        }
                                    }
                                }
                                @media (orientation: portrait) {
                                    & .answer-type-hint-text {
                                        margin-bottom: 0;
                                        font-size: 2vh;
                                    }

                                    & .answer-type-select-options {
                                        width: 100%;
                                        padding: 0;

                                        & .answer-type-select-option {
                                            font-size: 2vh;
                                            height: initial;

                                            & > i {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                                @media (orientation: landscape) {
                                    & .answer-type-select-options {
                                        flex-direction: row;
                                        & .answer-type-select-option {
                                            margin-top: 0;
                                            font-size: large;
                                            padding-left: 0;
                                            height: auto;

                                            &:not(:last-child) {
                                                margin-right: 5px;
                                            }
                                        }
                                    }
                                }
                            }

                            & .answer-type-controls-area {
                                height: initial;
                                margin-top: 0;
                            }
                        }

                        & .video-answer-text-container {
                            max-height: initial;
                            & .video-answer-textarea {
                                flex-grow: 1;
                                height: initial;
                            }

                            & .text-answer-buttons-container {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                margin-top: 0;
                                height: initial;
                                & .select-another-answer-type-button {
                                    height: initial;
                                    flex-basis: 100%;
                                }
                            }
                        }

                        & .video-answer-recorder, .audio-answer-recorder {
                            & .video-recorder-controls, .audio-recorder-controls {
                                height: initial;
                                margin-top: 0;
                                position: absolute;
                                bottom: .1em;
                                z-index: 60;
                                width: 100%;

                                & .controls {
                                    flex-direction: row;

                                    & .play-recording-button {
                                        display: none;
                                    }

                                    & .record-again-button {
                                        margin-right: 5px;
                                    }

                                    & .start-recording-button {
                                        margin-left: 5px;
                                        margin-right: 5px;
                                    }

                                    & .control-button {
                                        padding: 12px;
                                        white-space: normal;
                                        margin-left: 5px;

                                        &:first-child:not(:last-child) {
                                            margin-right: 5px;
                                        }
                                        &:last-child:not(:first-child) {
                                            margin-right: 5px;
                                        }

                                        &.stop-recording-button {
                                            @include video-button-danger;
                                        }

                                        height: initial;
                                        flex-grow: 1;
                                        svg {
                                            display: none;
                                        }
                                    }

                                    & .select-another-answer-type-button {
                                        flex-grow: 1;
                                        height: initial;
                                        flex-basis: 50%;
                                    }
                                }

                                & .select-another-answer-type-button {
                                    @include video-button-default;
                                    height: initial;
                                    flex-grow: 1;
                                }
                            }
                        }

                        & .video-answer-recorder {
                            flex-grow: 1;
                            display: flex;
                            max-height: initial;

                            &.video-answer-recorder-no-video {
                                margin-bottom: 0;
                            }

                            & .answer-recorder {
                                flex-grow: 1;
                                display: flex;

                                & .video-recorder {
                                    flex-grow: 1;
                                    display: flex;

                                    & .video-recorder-video {
                                        width: 100%;
                                        flex-grow: 1;
                                        display: flex;
                                        flex-direction: column;

                                        & > .placeholder {
                                          z-index: 50;
                                          flex-grow: 1;
                                        }

                                        & .video-react {
                                            padding: 0 !important;
                                            flex-grow: 1;
                                        }

                                        & .answer-recording-own-stream {
                                            flex-grow: 1;
                                            position: relative;
                                            overflow: auto;
                                            width: 100%;
                                            max-width: 100%;
                                            height: 0;
                                            display: flex;
                                            flex-direction: column;
                                            border-radius: 10px;
                                            vertical-align: top;
                                            box-sizing: border-box;
                                            background-color: #000;

                                            & .placeholder {
                                                z-index: 5;
                                                width: 100%;
                                                height: 100%;
                                                padding: 1em;
                                                flex-grow: 1;
                                            }

                                            & video {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                max-height: initial;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        & .audio-answer-recorder {
                            & .time-elapsed-display {
                                background-color: white;
                                color: rgba(0,0,0, .8);
                            }
                            & .audio-recorder {
                                & .audio-recorder-container {
                                    & .audio-text-overlay {
                                        & > h4 {
                                            padding-bottom: 0;
                                        }
                                    }

                                    & .audio-recorder-visualizer {
                                        height: 100px;
                                    }
                                }
                                & .audio-recorder-controls {
                                    & .control-button {
                                        @include audio-button-default;
                                        &.stop-recording-button {
                                            @include audio-button-danger;
                                        }
                                    }
                                    & .start-recording-button {
                                        position: relative;
                                        top: 0;
                                        width: initial;
                                        & .record-indicator {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
