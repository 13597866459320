.site-settings-box {
    padding-bottom: 0 !important;
    .site-settings-content {
        margin-bottom: 24px;
    }
    .btn-success {
        display: flex;
        margin: auto;
    }
}

.site-settings-text-container {
    border: 1px solid;

    .site-settings-text-title {
        background-color: #3caab5;
        text-align: center;
        margin-bottom: 10px;
        padding: 10px;
        color: #e9f1fd;
        font-weight: bold;
    }
    .ql-editor {
        max-height: 200px;
        overflow-y: scroll;
    }
}

.site-settings-accept {
    margin: 10px 0 20px 0;
    .site-settings-checkbox {
        padding: 0 !important;
        margin: -2px 5px 0 0;
        float: left;
    }
    label {
        color: #333;
        font-size: 16px;
        cursor: pointer;
        float: left;
    }
}

.registration-site-settings-title {
    background-color: #3caab5;
    h2 {
        color: #f2f6fc;
        text-align: center;
    }
    i {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        color: #f2f6fc;
        &:hover {
            opacity: 0.8;
        }
    }
}

.site-settings-public-page {
    margin: auto;
    background-color: white;
    min-height: 350px;
    z-index: 100;
    .site-settings-public-page-title {
        background-color: #3caab5;
        h3 {
            color: #f2f6fc;
            text-align: center;
            padding: 5px;
        }
    }
}
