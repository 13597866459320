.admin-on-demand-interview-template-load {
    width: 50%;
    float: right;
    @media (max-width: 1440px) {
        width: 80%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
    .select-template {
        width: 280px;
        margin: 2px 0 0 0;
        float: left;
        @media (max-width: 585px) {
            width: 100%;
        }
    }
    .buttons {
        width: calc(100% - 280px);
        float: left;
        @media (max-width: 585px) {
            width: 100%;
        }
        button {
            width: calc(50% - 0.5rem);
            float: left;
            margin: 0 0 0 0.5rem;
            @media (max-width: 585px) {
                width: 100%;
                margin: 10px 0 0 0;
            }
        }
    }
}
