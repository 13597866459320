.react-datepicker__day--outside-month {
  visibility: hidden;
}

.humanized-schedule {
  background-image: linear-gradient(#9c27b0, #9c27b0),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  font-weight: 400;
  padding: 7px 0;
  line-height: 1.428571429;
  color: #555;
}

.rdtOpen .humanized-schedule {
  background-image: linear-gradient(#9c27b0, #9c27b0),
    linear-gradient(hsl(0, 0%, 82%), #d2d2d2);
  background-size: 100% 2px, 100% 1px;
  transition-duration: 0.3s;
}

.scheduled-date-time-picker .rdt .rdtPicker {
  transition-duration: 0s;
}

.scheduler .rdtPicker {
  width: 398px;
}

.date-time-scheduler {
  display: flex;
}

.date-time-scheduler .rdtDays {
  flex: 1 0 248px;
}

.scheduled-date-time-picker .rdtPicker th.rdtSwitch {
  width: 100px;
}

.scheduled-date-time-picker .rdtPicker th.rdtNext, .scheduled-date-time-picker .rdtPicker th.rdtPrev {
  border-radius: 0;
}

.scheduled-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.schedule-time {
  margin-bottom: 15px;
}

.schedule-time label {
  display: block;
  color: #000;
  font-weight: 400;
}

.schedule-time input {
  width: 29%;
  margin: 0 1px;
  border: 1px solid #9c27b0;
  background: none;
  border-radius: 5px;
  margin-right: 3px;
  text-align: center;
  padding: 1px 0;
}

.tooglers span {
  margin-right: 14px;
  margin-left: 11px;
  position: relative;
  width: 0; 
  height: 0;
  cursor: pointer;
  display: inline-block; 
}

.tooglers-up span {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #9c27b0;
}

.tooglers-down {
  margin-top: -3px;
}

.tooglers-down span {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #9c27b0;
}

.scheduler-controls-wrapper {
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.scheduler-controls-wrapper .btn {
  background-color: #9c27b0;
  color: white;
}

@media (max-width: 521px) {
  .wrapper-scheduler {
    margin-bottom: 55px;
  }
  .date-time-scheduler {
    flex-direction: column;
  }
  .scheduler .rdtPicker {
    width: 260px;
  }
  .scheduled-time {
    align-items: center;
    margin-top: 20px;
    margin-left: 0;
  }
  .schedule-time {
    width: 70%;
    text-align: center;
  }
  .tooglers span {
    margin-right: 17px;
    margin-left: 17px;
  }
  .tooglers-up span {
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 10px;
    margin-bottom: 7px;
  }
  .tooglers-down {
    margin-top: 4px;
  }
  .tooglers-down span {
    border-left-width: 10px;
    border-right-width: 10px;
    border-top-width: 10px;
    margin-top: 7px;
  }
}
