.video-uploader {
    .dropzone {
        .alert {
            width: 515px;
        }
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 515px;
            height: 290px;
            font-size: 18px;
            border: 1px dotted black;
            border-radius: 10px;
            cursor: pointer;
            text-align: center;
        }
    }
    .placeholder {
        max-width: 515px;
        height: 290px;
        border-radius: 10px;
        font-size: 18px;
        background-color: black;
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .uploading-text {
            margin-bottom: .5em;
        }
    }

    .video-uploader-progress-indicator {
        max-width: 515px;
        height: 290px;
    }
}
