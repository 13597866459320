.review-card {
    h4 {
        margin: 0;
    }
    .reviews-list-rating-container {
        .reviews-list-rating {
            display: inline;
            margin: 0 0 0 -11px;
            height: 30px;
            svg {
                color: #eaaa4b
            }
        }
        .info {
            margin: 10px 0 0 0;
            @media (max-width: 520px) {
                display: none;
            }
        }
        .info-xs {
            margin: 15px 0 -5px 0;
            display: none;
            @media (max-width: 521px) {
                display: block;
            }
        }
    }

    .review-card-actions {
        margin: 10px
    }
}
